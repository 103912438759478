import React from "react";

import { MDBDataTableV5 } from "mdbreact";

import { useTranslation } from "react-i18next";

export default function Basic(props) {
    const { t } = useTranslation();
    let userAttributes;
    let i = 0;

    let datatable;
    [datatable] = React.useState("");

    userAttributes = [];
    for (const key of Object.keys(props.messages)) {
        userAttributes.push({
            dateReceived: Intl.DateTimeFormat("en-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "numeric",
                minute: "numeric",
            }).format(new Date(props.messages[key]["dateReceivedUTC.ISO8601"])),
            status:
                props.messages[key]["newMessage"] === true ? (
                    <div className="active-circle"></div>
                ) : (
                    <div className="inactive-circle"></div>
                ),
            subject:
                props.messages[key]["newMessage"] === true ? (
                    <div className="unreadMessage">
                        <p>{props.messages[key]["Subject"]}</p>
                    </div>
                ) : (
                    <div className="readMessage">
                        <p>{props.messages[key]["Subject"]}</p>
                    </div>
                ),
            clickEvent: () => props.toggleMessage(key),
        });

        i = i + 1;
    }

    datatable = {
        columns: [
            {
                label: t("messages.received"),
                field: "dateReceived",
            },
            {
                label: t("messages.subject"),
                field: "subject",
            },
        ],
        rows: userAttributes,
    };
    return (
        <MDBDataTableV5
            hover
            data={datatable}
            searchBottom={false}
            paging={false}
            className="messageData"
            info={false}
            noRecordsFoundLabel={t("recentservice.nomatches")}
            responsive
        />
    );
}
