import React, { Component } from "react";

import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    toast,
    ToastContainer,
    MDBIcon,
    MDBInput,
} from "mdbreact";

import Navigation from "../../../components/SubscriberMgmt/Navigation/Navigation";

import KeyInformation from "../../../components/SubscriberMgmt/KeyInformation/KeyInformation";

import DeviceInformation from "../../../components/SubscriberMgmt/DeviceInformation/DeviceInformation";

import Messages from "../../../components/SubscriberMgmt/Messages/Messages";

import CumulativeBandwidth from "../../../components/SubscriberMgmt/CumulativeBandwidth/CumulativeBandwidth";

import Upgrade from "../../../components/SubscriberMgmt/Upgrade/SelectPlan";

import UpgradeReview from "../../../components/SubscriberMgmt/Upgrade/Review";

import UpgradeCheckout from "../../../components/SubscriberMgmt/Upgrade/CheckOut";

import UpgradePaymentSuccess from "../../../components/SubscriberMgmt/Upgrade/PaymentSuccess";

import Cancel from "../../../components/SubscriberMgmt/Cancel/Cancel";

import axios from "../../../axios";

import parse from "html-react-parser";

import { withTranslation } from "react-i18next";

// import Joyride from "react-joyride";

import MyAccount from "../../../assets/images/myAccountSub.png";

import LogoFooter from "../../../components/Logo/logoFooter";

import brandsData from "../../../brands/common/brands.json";

import _ from "lodash";

class DashboardSubscriber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SessionList: [],
            venueName: [],
            keyNameListArray: null,
            loading: true,
            keys: undefined,
            modalMessage: true,
            messages: {},
            devices: [],
            isTourOpen: false,
            darkmode: JSON.parse(localStorage.getItem("darkMode")),
            selectedNetworkIndex: 0,
            paymentFailed: false,
            showError: false,
            keyNameArray: {},
        };
    }

    componentDidMount() {
        this.listKeys();
        document.title = "My Wi-Fi Network Dashboard";
	}

    handleSwitchChange = (nr) => () => {
        this.setState({
            darkmode: !JSON.parse(this.state.darkmode),
        });
        localStorage.setItem("darkMode", !JSON.parse(this.state.darkmode));
        let element = document.getElementsByTagName("body")[0];
        element.setAttribute(
            "data-theme",
            "darkMode-" + !JSON.parse(this.state.darkmode)
        );
    };

    listKeys = () => {
        this.setState({ loading: true });

        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
        };

        let keysAndVenueUnitPolicies = {};
        let keysAndVenueUnitPoliciesArray = [];
        let bodySessions = [];
        let iterationSessions = 0;
        let updatedSessions = [];

        let venueList = [];

        let keys;
        let keys1;
        let keys2 = [];
        let newKey = {};
        let updatedKeys = {};
        let suffixForSSID = "";
        axios
            .post("/subscriberconsole/listKeys", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    devices: [],
                });
                this.setState({ keys: [] });
                keys1 = response.data.KeysInKeyPools;
                this.setState({
                    subscriberToken: response.data.SubscriberToken,
                });
                venueList = Object.keys(response.data.KeysInKeyPools);

                if (
                    Object.values(keys1).findIndex(
                        (item) => item.ssid.toLowerCase() === "login to view"
                    ) === -1
                ) {
                    _.merge(updatedKeys, keys1);
                    for (let i = 0; i < Object.keys(keys1).length; i++) {
                        if (
                            keys1[Object.keys(keys1)[i]].ssid.toLowerCase() ===
                            "login to view"
                        ) {
                            // updatedKeys[Object.keys(keys1)[i]] = keys1[Object.keys(keys1)[i]]
                            // console.log(updatedKeys)
                            // console.log(Object.keys(keys1)[i])
                            keys2 = keys1[Object.keys(keys1)[i]];
                        }
                    }
                } else {
                    for (let i = 0; i < Object.keys(keys1).length; i++) {
                        if (
                            keys1[Object.keys(keys1)[i]].ssid.toLowerCase() ===
                            "login to view"
                        ) {
                            console.log(
                                keys1[Object.keys(keys1)[i]].ssid.toLowerCase()
                            );
                            updatedKeys[Object.keys(keys1)[i]] =
                                keys1[Object.keys(keys1)[i]];
                            // console.log(updatedKeys)
                            // console.log(Object.keys(keys1)[i])
                            keys2 = keys1[Object.keys(keys1)[i]];
                        }
                    }
                    for (let k = 0; k < Object.keys(keys2).length; k++) {
                        if (
                            Object.keys(keys2)[k] !== "DisplayOrder" &&
                            Object.keys(keys2)[k] !== "ssid"
                        ) {
                            // console.log(keys2[Object.keys(keys2)[k]]['psk'])
                            let keytagname = Object.keys(keys2)[k];
                            // console.log(keytagname)
                            var randomString = Math.random()
                                .toString(36)
                                .slice(2);
                            // let randomString = crypto.pbkdf2Sync(keys2[Object.keys(keys2)[k]]['psk'],'unifi', 4096, 32, "sha1").toString("hex");
                            // console.log(randomString)
                            const obj = keys2;
                            Object.defineProperty(
                                obj,
                                keytagname,
                                Object.getOwnPropertyDescriptor(obj, keytagname)
                            );
                            // delete keys2[keytagname];
                        }
                        const ssid = "login to view";
                        keys2 = { ...keys2, ssid };
                    }
                    updatedKeys["Ubiquiti5g"] = keys2;
                }

                console.log("updatedKeys: ", updatedKeys);
                for (let m = 0; m < Object.keys(updatedKeys).length; m++) {
                    let objectNumber = Object.keys(updatedKeys)[m];
                    let unitIDforSSID;
                    if (
                        updatedKeys[objectNumber]["ssid"].toLowerCase() ===
                        "login to view"
                    )
                        for (
                            let n = 0;
                            n < Object.keys(updatedKeys[objectNumber]).length;
                            n++
                        ) {
                            let indexNumber = Object.keys(
                                updatedKeys[objectNumber]
                            )[n];
                            if (
                                indexNumber !== "ssid" &&
                                indexNumber !== "DisplayOrder"
                            ) {
                                let venueUnitPolicyIDforSSID = Object.keys(
                                    updatedKeys[objectNumber][indexNumber][
                                        "VenueUnitPolicies"
                                    ]
                                );
                                // console.log(venueUnitPolicyIDforSSID)
                                console.log(
                                    updatedKeys[Object.keys(updatedKeys)[m]][
                                        indexNumber
                                    ]["VenueUnitPolicies"]
                                );
                                if (
                                    updatedKeys[objectNumber][
                                        "ssid"
                                    ].toLowerCase() === "login to view"
                                )
                                    unitIDforSSID =
                                        updatedKeys[
                                            Object.keys(updatedKeys)[m]
                                        ][indexNumber]["VenueUnitPolicies"][
                                            venueUnitPolicyIDforSSID
                                        ]["UnitID"];
                                suffixForSSID =
                                    " " +
                                    updatedKeys[Object.keys(updatedKeys)[m]][
                                        indexNumber
                                    ]["VenueUnitPolicies"][
                                        venueUnitPolicyIDforSSID
                                    ]["VenueAddressLine2"];
                                // console.log(unitIDforSSID)
                            }
                            // console.log(unitIDforSSID)
                            if (
                                unitIDforSSID !== undefined &&
                                updatedKeys[objectNumber][
                                    "ssid"
                                ].toLowerCase() === "login to view"
                            ) {
                                if (objectNumber === "Ubiquiti5g") {
                                    const ssid = unitIDforSSID + suffixForSSID;
                                    const DisplayOrder = "10";
                                    updatedKeys[objectNumber] = {
                                        ...updatedKeys[objectNumber],
                                        ssid,
                                        DisplayOrder,
                                    };
                                } else {
                                    const ssid = unitIDforSSID;
                                    const DisplayOrder = "50";
                                    updatedKeys[objectNumber] = {
                                        ...updatedKeys[objectNumber],
                                        ssid,
                                        DisplayOrder,
                                    };
                                }
                            }
                        }
                }
                // console.log(updatedKeys)
                var sortedKeys = _(updatedKeys)
                    .map(function (v, k) {
                        // insert the key into the object
                        return _.merge({}, v, { key: k });
                    })
                    .sortBy("DisplayOrder") // sort by name
                    .value();

                // console.log("sortedKeys", sortedKeys)
                this.setState({ venueList: Object.keys(updatedKeys) });
                this.setState({ keys: [updatedKeys] });
                this.setState({ sortedKeys: sortedKeys });
                this.setState({ venueName: this.state.venueList });
                this.setState({
                    devices: response.data.AllDevices,
                    deviceCountOffline: response.data.DeviceCountOffline,
                    deviceCountOnline: response.data.DeviceCountOnline,
                    messages: response.data.messages_from_venues,
                    allData: response.data,
                    token: response.data.SubscriberToken,
                });
                sessionStorage.setItem(
                    "emailSubscriber",
                    response.data.EmailAddress
                );
                sessionStorage.setItem(
                    "phoneNumberSubscriber",
                    response.data["PhoneNumber.E164"]
                );
                sessionStorage.setItem("firstName", response.data.GivenName);
                sessionStorage.setItem("lastName", response.data.FamilyName);
                let keyNameArray = [];
                let keyNameListArray = [];
                let keyNameListArrayAll = [];
                let allVenueUnitPolicies = {};
                var obj = {};
                var obj_all = {};
                for (let i = 0; i < this.state.sortedKeys.length; i++) {
                    keyNameArray.push(this.state.sortedKeys[i]);
                    obj[i] = {};
                    let keyNameList = Object.keys(this.state.sortedKeys[i]);
                    // console.log(keyNameList)
                    for (
                        let j = 0;
                        j < Object.keys(this.state.sortedKeys[i]).length;
                        j++
                    ) {
                        let keyPoolID = keyNameList[j];
                        if (
                            this.state.sortedKeys[i][keyPoolID]["KeyTag"] !==
                            undefined
                        ) {
                            // console.log(this.state.sortedKeys[i][keyPoolID]["VenueUnitPolicies"])
                            this.setState({
                                venueUnitPolicies:
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ],
                            });
                            // console.log(this.state.sortedKeys[i][keyPoolID]["VenueUnitPolicies"])
                            for (
                                let j = 0;
                                j <=
                                Object.keys(
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ]
                                ).length;
                                j++
                            ) {
                                // console.log(this.state.sortedKeys[i][keyPoolID]["VenueUnitPolicies"])
                                // allVenueUnitPolicies.push(this.state.sortedKeys[i][keyPoolID]["VenueUnitPolicies"][j])
                            }
                            // console.log("keyPoolID", keyPoolID)
                            for (const [key, value] of Object.entries(
                                this.state.sortedKeys[i][keyPoolID]
                            )) {
                                // console.log(value);
                                // allVenueUnitPolicies.push(value)
                            }
                            allVenueUnitPolicies[keyPoolID] =
                                this.state.sortedKeys[i][keyPoolID];
                            obj[i][keyPoolID] = 0;
                            obj[i][keyPoolID] =
                                this.state.sortedKeys[i][keyPoolID]["KeyTag"];
                            obj_all[i] = this.state.sortedKeys[i][keyPoolID];
                            obj_all[i].keyTag = keyPoolID;
                            obj_all[i].network =
                                this.state.sortedKeys[i]["ssid"];

                            obj_all[i].selectedNetworkIndex = i;

                            let venueUnitPolicyID = Object.keys(
                                this.state.sortedKeys[i][keyPoolID][
                                    "VenueUnitPolicies"
                                ]
                            );
                            obj_all[i].venueID =
                                this.state.sortedKeys[i][keyPoolID][
                                    "VenueUnitPolicies"
                                ][venueUnitPolicyID[0]]["VenueID"];
                            obj_all[i].servicePlanID =
                                this.state.sortedKeys[i][keyPoolID][
                                    "VenueUnitPolicies"
                                ][venueUnitPolicyID[0]]["ServicePlan"][
                                    "ServicePlanID"
                                ];
                            // this.setState({
                            // 	paymentFailed:
                            // 		this.state.sortedKeys[i][keyPoolID][
                            // 			"VenueUnitPolicies"
                            // 		][venueUnitPolicyID[0]]["paymentFailed"],
                            // });
                            if (
                                !this.state.sortedKeys[i][keyPoolID][
                                    "wpa2enterprise_auth"
                                ]
                            ) {
                                this.setState({
                                    paymentFailed:
                                        this.state.sortedKeys[i][keyPoolID][
                                            "VenueUnitPolicies"
                                        ][venueUnitPolicyID[0]][
                                            "paymentFailed"
                                        ],
                                });
                                sessionStorage.setItem(
                                    "venueIDSubscriber",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["VenueID"]
                                );
                                sessionStorage.setItem(
                                    "stripeClientSecretSubscriber",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]][
                                        "StripeClientSecret"
                                    ]
                                );
                                sessionStorage.setItem(
                                    "unitID_internalSubscriber",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["UnitID_internal"]
                                );
                                sessionStorage.setItem(
                                    "servicePlanIDSubscriber",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["ServicePlan"][
                                        "ServicePlanID"
                                    ]
                                );
                                sessionStorage.setItem(
                                    "servicePlanNameSubscriber",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["ServicePlan"][
                                        "ServicePlanName"
                                    ]
                                );
                                sessionStorage.setItem(
                                    "servicePlanNameSubscriber",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["ServicePlan"][
                                        "ServicePlanName"
                                    ]
                                );
                                sessionStorage.setItem(
                                    "address",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["AddressLine1"]
                                );
                                sessionStorage.setItem(
                                    "city",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["City"]
                                );
                                sessionStorage.setItem(
                                    "state",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["State"]
                                );
                                sessionStorage.setItem(
                                    "postalcode",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["PostalCode"]
                                );
                                sessionStorage.setItem(
                                    "unit",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["UnitID_internal"]
                                );
                                sessionStorage.setItem(
                                    "unitName",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["UnitID"]
                                );
                                sessionStorage.setItem(
                                    "dateStart",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]][
                                        "StartDateTimeUTC.ISO8601"
                                    ]
                                );
                                sessionStorage.setItem(
                                    "logoURL",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["DisplayOptions"][
                                        "LogoPNG.URL"
                                    ]
                                );
                                sessionStorage.setItem(
                                    "supportEmail",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["DisplayOptions"][
                                        "VenueSupportEmailAddress"
                                    ]
                                );
                                sessionStorage.setItem(
                                    "supportPhone",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["DisplayOptions"][
                                        "VenueSupportPhoneNumber.E164"
                                    ]
                                );
                            }
                        }
                    }
                }
                // console.log("keyNameArray", keyNameArray);
                keyNameListArrayAll.push(obj_all);
                keyNameListArray.push(obj);
                // console.log(obj_all)
                // console.log("keyNameListArrayAll", keyNameListArrayAll)
                //  const uniqueOptionVenueUnitPolicy = this.removeDuplicates(
                //   allVenueUnitPolicies,
                //   "UnitID_internal"
                // );
                this.setState({ keyNameListArray: keyNameListArray });
                this.setState({ keyNameListArrayAll: keyNameListArrayAll });
                this.setState({ keyNameArray: keyNameArray });
                // this.setState({ allVenueUnitPolicies: uniqueOptionVenueUnitPolicy});
                // console.log("allVenueUnitPolicies", allVenueUnitPolicies)
                Object.keys(allVenueUnitPolicies).map((oneKey, i) => {
                    // console.log(oneKey)
                    keysAndVenueUnitPolicies[i] = 0;
                    // keysAndVenueUnitPoliciesArray.push(oneKey)
                    Object.keys(
                        allVenueUnitPolicies[oneKey]["VenueUnitPolicies"]
                    ).map((twoKey, j) => {
                        keysAndVenueUnitPolicies[i] = {
                            ...keysAndVenueUnitPolicies[i],
                            keyTag: oneKey,
                            venueUnitPolicyID: twoKey,
                        };
                        // console.log(keysAndVenueUnitPolicies)
                        keysAndVenueUnitPoliciesArray.push({
                            VenueUnitPolicyID: twoKey,
                            SSID: allVenueUnitPolicies[oneKey]["network"],
                            Profile: allVenueUnitPolicies[oneKey]["KeyTag"],
                            PSK: allVenueUnitPolicies[oneKey]["psk"],
                            UnitID: allVenueUnitPolicies[oneKey][
                                "VenueUnitPolicies"
                            ][twoKey]["UnitID"],
                            UnitBuilding:
                                allVenueUnitPolicies[oneKey][
                                    "VenueUnitPolicies"
                                ][twoKey]["UnitBuilding"],
                            UnitFloor:
                                allVenueUnitPolicies[oneKey][
                                    "VenueUnitPolicies"
                                ][twoKey]["UnitFloor"],
                            VenueName:
                                allVenueUnitPolicies[oneKey][
                                    "VenueUnitPolicies"
                                ][twoKey]["VenueName"],
                            keyID: oneKey,
                        });
                        // keysAndVenueUnitPolicies[i][j] = allVenueUnitPolicies[oneKey]["VenueUnitPolicies"]
                    });
                });
                this.setState({
                    keysAndVenueUnitPoliciesArray:
                        keysAndVenueUnitPoliciesArray,
                });
                const sessionList = sortedKeys;
                let SessionListID = null;

                bodySessions.push(sessionList);
                bodySessions.forEach((val) => {
                    SessionListID = Object.keys(bodySessions[0]);
                });
                if (this.state.SessionList !== []) {
                    this.setState({ SessionList: [] });
                }
                SessionListID.forEach((val, index) => {
                    updatedSessions = bodySessions.map((sessionList) => {
                        return {
                            ...sessionList[SessionListID[iterationSessions]],
                        };
                    });
                    if (updatedSessions[0] !== undefined) {
                        this.setState({
                            SessionList: [
                                ...this.state.SessionList,
                                updatedSessions,
                            ],
                        });
                        iterationSessions = iterationSessions + 1;
                    }
                });

                if (this.state.keys !== undefined) {
                    this.setState({ loading: false });
                }
                if (this.state.keyNameArray.length !== 0) {
                    this.getServicePlans();
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.setState({ showError: true });
                this.setState({ error: error });
            });
    };
    removeDuplicates(arr, prop) {
        return arr.filter(
            (obj, index, self) =>
                index === self.findIndex((o) => o[prop] === obj[prop])
        );
    }
    getServicePlans = () => {
        let selectedKeyID;
        let servicePlanID;
        let filteredServicePlans = [];
        let billingCycleHours;
        let billingCycleRecurrance;
        let billingCyclePrice;
        for (
            let i = 0;
            i <
            Object.keys(
                this.state.keyNameArray[this.state.selectedNetworkIndex]
            ).length;
            i++
        ) {
            if (
                Object.keys(
                    this.state.keyNameArray[this.state.selectedNetworkIndex]
                )[i] !== "DisplayOrder" &&
                Object.keys(
                    this.state.keyNameArray[this.state.selectedNetworkIndex]
                )[i] !== "ssid" &&
                Object.keys(
                    this.state.keyNameArray[this.state.selectedNetworkIndex]
                )[i] !== "key"
            ) {
                selectedKeyID = Object.keys(
                    this.state.keyNameArray[this.state.selectedNetworkIndex]
                )[i];
                servicePlanID =
                    this.state.keyNameArray[this.state.selectedNetworkIndex][
                        selectedKeyID
                    ]["servicePlanID"];
            }
        }
        let body;
        if (sessionStorage.getItem("unit") !== null) {
            body = {
                UnitID_internal: sessionStorage.getItem("unit"),
            };
        } else {
            body = {
                VenueID: sessionStorage.getItem("venueID"),
            };
        }
        axios
            .post(
                "/networkoperatorconsole/shoppingcart/listServicePlans",
                body,
                {
                    headers: {},
                }
            )
            .then((response) => {
                let sortedServicePlans = _(response.data.AvailableServicePlans)
                    .map(function (v, k) {
                        // insert the key into the object
                        return _.merge({}, v, { key: k });
                    })
                    .sortBy("BillingCyclePrice.USD")
                    .reverse() // sort by name
                    .value();
                for (let i = 0; i < sortedServicePlans.length; i++) {
                    if (
                        sortedServicePlans[i]["ServicePlanID"] ===
                        sessionStorage.getItem("servicePlanIDSubscriber")
                    ) {
                        sessionStorage.setItem(
                            "servicePlanPriceSubscriber",
                            sortedServicePlans[i]["BillingCyclePrice.USD"] / 100
                        );
                        sessionStorage.setItem(
                            "BillingCycleHours",
                            sortedServicePlans[i]["BillingCycleHours"]
                        );
                        sessionStorage.setItem(
                            "BillingCycleRecurrence",
                            sortedServicePlans[i]["BillingCycleRecurrence"]
                        );
                        billingCycleHours =
                            sortedServicePlans[i]["BillingCycleHours"];
                        billingCycleRecurrance =
                            sortedServicePlans[i]["BillingCycleRecurrence"];
                        billingCyclePrice =
                            sortedServicePlans[i]["BillingCyclePrice.USD"];
                        this.setState({
                            ServicePlanName:
                                sortedServicePlans[i]["ServicePlanName"],

                            BillingCyclePrice:
                                sortedServicePlans[i]["BillingCyclePrice.USD"] /
                                100,

                            BillingCycleHours:
                                sortedServicePlans[i]["BillingCycleHours"],
                        });
                    }
                }
                // console.log(billingCyclePrice)
                for (let i = 0; i < sortedServicePlans.length; i++) {
                    if (
                        sortedServicePlans[i]["BillingCycleHours"] ===
                            billingCycleHours &&
                        sortedServicePlans[i]["BillingCycleRecurrence"] ===
                            billingCycleRecurrance &&
                        sortedServicePlans[i]["BillingCyclePrice.USD"] !==
                            billingCyclePrice
                    ) {
                        filteredServicePlans.push(sortedServicePlans[i]);
                    }

                    sessionStorage.setItem(
                        "AvailableServicePlans",
                        JSON.stringify(filteredServicePlans)
                    );
                    sessionStorage.setItem(
                        "AvailableServicePlanCount",
                        filteredServicePlans.length
                    );
                }

                for (let i = 0; i < filteredServicePlans.length; i++) {
                    // console.log(servicePlanID)
                    if (
                        filteredServicePlans[i]["ServicePlanID"] ===
                        servicePlanID
                    ) {
                    }
                    // console.log(filteredServicePlans[i]["BillingCyclePrice.USD"])
                    // console.log(billingCyclePrice)
                    if (
                        filteredServicePlans[i]["BillingCyclePrice.USD"] >
                        billingCyclePrice
                    ) {
                        this.setState({
                            UpgradeServicePlanName:
                                filteredServicePlans[i]["ServicePlanName"],
                            UpgradeBillingCyclePrice:
                                filteredServicePlans[i][
                                    "BillingCyclePrice.USD"
                                ] / 100,
                        });
                    }
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.setState({ error: error });
            });
    };

    toggleMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
    };

    toggleNotifications = () => {
        this.setState({ toggleNotifications: "toggledOff" });
    };

    submitConfirmMessage = (index) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            MessageID: index,
        };
        axios
            .post("/subscriberconsole/acknowledgeMessageFromVenue", body, {
                headers: {},
            })
            .then((response) => {
                toast.success("Message Read!", {
                    closeButton: false,
                });
                this.listKeys();
            })
            .catch((error) => {
                this.setState({ error: true });
            });
    };
    closeTour2 = () => {
        this.setState({
            isTourOpen: !this.state.isTourOpen,
        });
    };
    closeTour = () => {
        this.setState({
            isTourOpen: !this.state.isTourOpen,
            steps: [
                {
                    placement: "center",
                    target: "body",
                    content: (
                        <div className="first-step-content">
                            <h4>Welcome to Dish Fiber!</h4>
                            <p>
                                Welcome to the DISH Fiber Wi-Fi management
                                portal here you can manager your Profiles,
                                devices and account details
                            </p>
                            <MDBInput
                                label="Do not show again"
                                type="checkbox"
                                id="tourNotShownAgain"
                                name="tourNotShownAgain"
                                onChange={this.toggleCheckboxValueTour}
                            />
                        </div>
                    ),
                },
                {
                    target: ".second-step",
                    content: (
                        <div className="second-step-content">
                            <h4>Profiles</h4>
                            <p></p>
                        </div>
                    ),
                    styles: {
                        minWidth: "400px",
                        textAlign: "center",
                    },
                },
                {
                    target: ".third-step",
                    content: (
                        <div className="third-step-content">
                            <h4>Bandwidth</h4>
                            <p></p>
                        </div>
                    ),
                    styles: {
                        minWidth: "400px",
                        textAlign: "center",
                    },
                },
                {
                    target: ".devices-step",
                    content: (
                        <div className="third-step-content">
                            <h4>Devices</h4>
                            <p></p>
                        </div>
                    ),
                    styles: {
                        minWidth: "400px",
                        textAlign: "center",
                    },
                },
                {
                    target: ".fourth-step",
                    content: (
                        <div className="third-step-content">
                            <h4>Messages</h4>
                            <p></p>
                        </div>
                    ),
                    styles: {
                        minWidth: "400px",
                        textAlign: "center",
                    },
                },
                {
                    target: ".fifth-step",
                    content: (
                        <div className="third-step-content">
                            <h4>Account Settings</h4>
                            <br />
                            <img src={MyAccount} alt="Account Settings" />
                        </div>
                    ),
                    styles: {
                        minWidth: "400px",
                        textAlign: "center",
                    },
                },
                {
                    placement: "center",
                    target: "body",
                    content: (
                        <div>
                            <p>Thank You!</p>
                            <MDBInput
                                label="Do not show again"
                                type="checkbox"
                                id="tourNotShownAgain"
                                name="tourNotShownAgain"
                                onChange={this.toggleCheckboxValueTour}
                            />
                            <MDBBtn color="danger" onClick={this.closeTour2}>
                                Close Tour
                            </MDBBtn>
                        </div>
                    ),
                },
            ],
            stepsMobile: [
                {
                    placement: "center",
                    target: "body",
                    content: (
                        <div className="first-step-content">
                            <h4>Welcome to Dish Fiber!</h4>
                            <p>
                                Welcome to the DISH Fiber Wi-Fi management
                                portal here you can manager your Propfiles,
                                devices and account details
                            </p>
                        </div>
                    ),
                    styles: {
                        textAlign: "center",
                    },
                    isFixed: true,
                },
                {
                    target: ".second-step",
                    content: (
                        <div className="second-step-content">
                            <h4>Profiles</h4>
                            <p></p>
                        </div>
                    ),
                    styles: {
                        textAlign: "center",
                    },
                    placement: "bottom",
                },
                {
                    target: ".third-step",
                    content: (
                        <div className="third-step-content">
                            <h4>Bandwidth</h4>
                            <p></p>
                        </div>
                    ),
                    styles: {
                        textAlign: "center",
                    },
                    placement: "bottom",
                },
                {
                    target: ".devices-step",
                    content: (
                        <div className="third-step-content">
                            <h4>Devices</h4>
                            <p></p>
                        </div>
                    ),
                    styles: {
                        textAlign: "center",
                    },
                    isFixed: true,
                },
                {
                    target: ".fourth-step",
                    content: (
                        <div className="third-step-content">
                            <h4>Messages</h4>
                            <p></p>
                        </div>
                    ),
                    styles: {
                        textAlign: "center",
                    },
                    placement: "top",
                },
                {
                    target: ".fifth-step",
                    content: (
                        <div className="third-step-content">
                            <h4>Account Settings</h4>
                            <br />
                            <img src={MyAccount} alt="Account Settings" />
                        </div>
                    ),
                    styles: {
                        textAlign: "center",
                    },
                    isFixed: true,
                },
                {
                    placement: "center",
                    target: "body",
                    content: (
                        <div>
                            <p>Thank You!</p>
                            <MDBInput
                                label="Do not show again"
                                type="checkbox"
                                id="tourNotShownAgain"
                                name="tourNotShownAgain"
                                onChange={this.toggleCheckboxValueTour}
                            />
                            <MDBBtn color="danger" onClick={this.closeTour2}>
                                Close Tour
                            </MDBBtn>
                        </div>
                    ),
                    styles: {
                        textAlign: "center",
                    },
                    isFixed: true,
                },
                {
                    content: (
                        <div>
                            You can render anything!
                            <br />
                            <h3>Like this H3 title</h3>
                        </div>
                    ),
                    placement: "top",
                    target: ".demo__how-it-works h2",
                    title: "Our Mission",
                },
            ],
        });
    };

    setCurrentSlide = (curr) => {
        var p = document.getElementsByClassName("customClass");

        if (p[0] !== undefined && curr === 4) {
            if (p[0].classList.contains("active") === true) {
                p[0].classList.remove("active");
            }
        }
        if (p[0] !== undefined && curr === 5) {
            if (p[0].classList.contains("active") === false) {
                p[0].classList.add("active");
                return p[0].click();
            }
        }
    };

    toggleCheckboxValueTour = () => {
        this.setState({
            tourNotShownAgain: !this.state.tourNotShownAgain,
        });
    };

    showUpgradeModal = () => {
        this.setState({
            modalUpgrade: !this.state.modalUpgrade,
        });
    };

    onSignOutClick = () => {
        let element = document.getElementsByTagName("body")[0];
        element.setAttribute("data-theme", "darkMode-false");
        sessionStorage.clear();
        this.setState({ isLogged: false });
        window.location.href = "/";
    };

    render() {
        // console.log(this.state.UpgradeBillingCyclePrice)
        const windowWidth = window.innerWidth;
        const { steps, stepsMobile } = this.state;
        const { t } = this.props;
        let showUpgrade;
        let brandName;
        let phoneNumber;
        let emailAddress;
        brandsData.forEach(
            ({ name, url, phone_number, flag_upgrade, email_address }) => {
                if (url.includes(window.location.hostname)) {
                    brandName = name;
                    showUpgrade = flag_upgrade;
                    phoneNumber = phone_number;
                    emailAddress = email_address;
                }
            }
        );
        let newPrice;
        // console.log(this.state.BillingCyclePrice)
        if (
            this.state.BillingCyclePrice !== undefined &&
            this.state.UpgradeBillingCyclePrice !== undefined
        ) {
            newPrice =
                this.state.UpgradeBillingCyclePrice.toFixed(2) -
                this.state.BillingCyclePrice.toFixed(2);
        }
        // console.log(newPrice)
        return (
            <>
                <div ref={(el) => (this.div = el)}></div>
                <MDBModal
                    isOpen={this.state.modalUpgrade}
                    toggle={this.showUpgradeModal}
                    centered
                >
                    <MDBModalHeader
                        toggle={this.showUpgradeModal}
                        className="noBorder"
                    ></MDBModalHeader>

                    <MDBModalBody className="updatePayment">
                        <h2>Modify Service Plan Feature Coming Soon</h2>
                        <p>
                            In order to process your request please contact our
                            Customer Support team at {phoneNumber} or send us an
                            email to{" "}
                            <a href={"mailto:" + emailAddress}>
                                {emailAddress}
                            </a>
                            , with your information and desired speed to
                            complete the change for your account.
                        </p>
                    </MDBModalBody>
                </MDBModal>
                {this.state.paymentFailed === true ? (
                    <MDBModal isOpen={true} centered>
                        <MDBModalBody className="updatePayment">
                            <h2>
                                We encountered a problem processing your
                                payment.
                            </h2>
                            {/* <p>
                Please{" "}
                <a href="/update/checkout?ui=myaccount">click here</a> to update your payment information.
							       </p> */}
                            <p>
                                If you have any questions, contact support at{" "}
                                {sessionStorage.getItem("supportPhone")}.
                            </p>
                            <button className="button-danger Ripple-parent">
                                <a
                                    href="/update/checkout?ui=myaccount"
                                    rel="noreferrer"
                                >
                                    Update Payment Info
                                </a>
                            </button>
                            <button
                                className="button-danger Ripple-parent"
                                onClick={this.onSignOutClick}
                            >
                                Log Out & Update Later
                            </button>
                        </MDBModalBody>
                    </MDBModal>
                ) : (
                    ""
                )}
                {this.state.loading === true ? (
                    <div id="overlay" style={{ display: "block" }}>
                        <div class="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div
                            id="subscriberUI"
                            className={
                                this.state.paymentFailed === true
                                    ? "blurred"
                                    : ""
                            }
                            data-theme={
                                "darkMode-" + localStorage.getItem("darkMode")
                            }
                        >
                            {/* <Joyride
								steps={windowWidth > 767 ? steps : stepsMobile}
								run={this.state.isTourOpen}
								continuous={true}
								showProgress={true}
								styles={{
									options: {
										beaconSize: 1,
									},
								}}
								scrollDuration={500}
							/> */}
                            <ToastContainer
                                hideProgressBar={true}
                                newestOnTop={true}
								autoClose={5000}
                            />
                            <Navigation
                                allData={this.state.allData}
                                listKeys={this.listKeys}
                                SessionList={this.state.SessionList}
                                keys={this.state.keys}
                                venueName={this.state.venueName}
                                closeTour={this.closeTour}
                                handleSwitchChange={this.handleSwitchChange}
                                darkMode={this.state.darkMode}
                                UpgradeServicePlanName={
                                    this.state.UpgradeServicePlanName
                                }
                            />
                            {Object.keys(this.state.messages).length !== 0 &&
                            this.state.messages[
                                Object.keys(this.state.messages)[0]
                            ]["newMessage"] !== false ? (
                                <MDBModal
                                    isOpen={this.state.modalMessage}
                                    toggle={this.toggleMessage}
                                    centered
                                >
                                    <MDBModalHeader toggle={this.toggleMessage}>
                                        Messages
                                    </MDBModalHeader>
                                    <MDBModalBody>
                                        <MDBRow className="messageSubject">
                                            <MDBCol>
                                                <p>Subject:</p>
                                                {
                                                    this.state.messages[
                                                        Object.keys(
                                                            this.state.messages
                                                        )[0]
                                                    ]["Subject"]
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol>
                                                <p>Message:</p>
                                                {parse(
                                                    this.state.messages[
                                                        Object.keys(
                                                            this.state.messages
                                                        )[0]
                                                    ]["Message"]
                                                )}
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBModalBody>
                                    <MDBModalFooter>
                                        <MDBBtn
                                            color="danger"
                                            onClick={(e) =>
                                                this.submitConfirmMessage(
                                                    Object.keys(
                                                        this.state.messages
                                                    )[0]
                                                )
                                            }
                                        >
                                            Acknowledge
                                        </MDBBtn>
                                    </MDBModalFooter>
                                </MDBModal>
                            ) : null}
                            {showUpgrade &&
                            this.state.UpgradeServicePlanName !== undefined &&
                            window.location.pathname === "/myaccount" &&
                            newPrice !== 0 &&
                            newPrice !== undefined ? (
                                <div
                                    className={
                                        "topBanner " +
                                        this.state.toggleNotifications
                                    }
                                >
                                    <h2>
                                        You are on the{" "}
                                        {this.state.ServicePlanName} plan,{" "}
                                        <a href="/myaccount/upgrade">
                                            Click here
                                        </a>{" "}
                                        to upgrade to{" "}
                                        {this.state.UpgradeServicePlanName} for
                                        only ${newPrice.toFixed(2)} more per
                                        month!
                                    </h2>
                                    <MDBIcon
                                        icon="times-circle"
                                        onClick={this.toggleNotifications}
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                            {window.location.pathname ===
                            "/myaccount/upgrade" ? (
                                <Upgrade />
                            ) : window.location.pathname ===
                              "/myaccount/upgrade/review" ? (
                                <UpgradeReview />
                            ) : window.location.pathname ===
                              "/myaccount/upgrade/checkout" ? (
                                <UpgradeCheckout />
                            ) : window.location.pathname ===
                              "/myaccount/upgrade/paymentsuccess" ? (
                                <UpgradePaymentSuccess />
                            ) : window.location.pathname ===
                              "/myaccount/cancel" ? (
                                <Cancel />
                            ) : (
                                <div className="mainDashboard subscriberUIDashboard">
                                    <MDBContainer fluid>
                                        <MDBRow className="dashboardRow">
                                            <MDBCol
                                                sm="12"
                                                xl="6"
                                                className="second-step"
                                            >
                                                {this.state.SessionList
                                                    .length !== 0 ? (
                                                    <KeyInformation
                                                        listKeys={this.listKeys}
                                                        SessionList={
                                                            this.state
                                                                .SessionList
                                                        }
                                                        keys={this.state.keys}
                                                        sortedKeys={
                                                            this.state
                                                                .sortedKeys
                                                        }
                                                        venueName={
                                                            this.state.venueName
                                                        }
                                                        keyNameListArray={
                                                            this.state
                                                                .keyNameListArray
                                                        }
                                                        keyNameListArrayAll={
                                                            this.state
                                                                .keyNameListArrayAll
                                                        }
                                                        loading={
                                                            this.state.loading
                                                        }
                                                        selectedKeyID={
                                                            this.state
                                                                .selectedKeyID
                                                        }
                                                        token={this.state.token}
                                                        venueUnitPolicies={
                                                            this.state
                                                                .venueUnitPolicies
                                                        }
                                                        allVenueUnitPolicies={
                                                            this.state
                                                                .allVenueUnitPolicies
                                                        }
                                                        keysAndVenueUnitPoliciesArray={
                                                            this.state
                                                                .keysAndVenueUnitPoliciesArray
                                                        }
                                                        subscriberToken={
                                                            this.state
                                                                .subscriberToken
                                                        }
                                                    />
                                                ) : (
                                                    <>
                                                        <div
                                                            className="ctaButtonNoAction"
                                                            onClick={
                                                                this
                                                                    .toggleManageProfiles
                                                            }
                                                        >
                                                            <h2>
                                                                <MDBIcon icon="wifi" />{" "}
                                                                {t(
                                                                    "profile.title"
                                                                )}
                                                            </h2>
                                                        </div>
                                                        <MDBCard
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                minHeight:
                                                                    "230px",
                                                                marginBottom:
                                                                    "20px",
                                                            }}
                                                        >
                                                            <MDBCardBody
                                                                className="messageCardBody1"
                                                                style={{
                                                                    flex: "unset",
                                                                }}
                                                            >
                                                                <h4
                                                                    style={{
                                                                        color: "#BBB",
                                                                    }}
                                                                >
                                                                    {t(
                                                                        "profile.nokeys"
                                                                    )}
                                                                </h4>
                                                            </MDBCardBody>
                                                        </MDBCard>
                                                    </>
                                                )}
                                            </MDBCol>
                                            <MDBCol
                                                sm="12"
                                                xl="6"
                                                className="third-step"
                                            >
                                                <CumulativeBandwidth
                                                    selectedKeyID={
                                                        this.props.selectedKeyID
                                                    }
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow className="dashboardRow">
                                            <MDBCol
                                                sm="12"
                                                xl="6"
                                                className="devices-step"
                                            >
                                                <DeviceInformation
                                                    deviceControlID={Object.keys(
                                                        this.state.devices
                                                    )}
                                                    devices={this.state.devices}
                                                    deviceCountOffline={
                                                        this.state
                                                            .deviceCountOffline
                                                    }
                                                    deviceCountOnline={
                                                        this.state
                                                            .deviceCountOnline
                                                    }
                                                    listKeys={this.listKeys}
                                                />
                                            </MDBCol>
                                            <MDBCol
                                                sm="12"
                                                xl="6"
                                                className="fourth-step"
                                            >
                                                <Messages
                                                    messages={
                                                        this.state.messages
                                                    }
                                                    listKeys={this.listKeys}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                </div>
                            )}
                            <div className="footer subscriberUI">
                                <MDBContainer fluid>
                                    <MDBRow>
                                        <MDBCol className="securedByVaultImage">
                                            <LogoFooter
                                                darkMode={localStorage.getItem(
                                                    "darkMode"
                                                )}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }
}

export default withTranslation()(DashboardSubscriber);
