import React, { Component } from "react";
import {
	MDBBtn,
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBModalFooter,
	MDBRow,
	MDBCol,
	MDBIcon,
	toast,
	MDBTabPane,
	MDBTabContent,
	MDBNav,
	MDBNavItem,
	MDBNavLink,
	MDBSpinner,
	MDBInput,
  MDBTooltip
} from "mdbreact";
import { withTranslation } from "react-i18next";
import axios from "../../../axios";
import OperatorSupportedLocalesTable from "../../../components/NetworkMgmt/Settings/Datatables/OperatorSupportedLocalesTable";
import OperatorAdministratorsTable from "../../../components/NetworkMgmt/Settings/Datatables/OperatorAdministratorsTableMUI";
import OperatorServicePlansTable from "../../../components/NetworkMgmt/Settings/Datatables/OperatorServicePlansTable";
import OperatorRadiusServerTable from "../../../components/NetworkMgmt/Settings/Datatables/OperatorRadiusServerTable";
import OperatorWiFiControllerTable from "../../../components/NetworkMgmt/Settings/Datatables/OperatorWiFiControllerTable";
import OperatorKeypoolsTable from "../../../components/NetworkMgmt/Settings/Datatables/OperatorKeypoolsTable";
import AddServicePlan from "../../../components/NetworkMgmt/Forms/AddServicePlan/AddServicePlan";
import ModifyServicePlan from "../../../components/NetworkMgmt/Forms/ModifyServicePlan/ModifyServicePlan";
import AddRadiusServer from "../../../components/NetworkMgmt/Forms/AddRadiusServer/AddRadiusServer";
import AddDomainAdministrator from "../../../components/NetworkMgmt/Forms/AddDomainAdministrator/AddDomainAdministrator";
import AddKeypool from "../../../components/NetworkMgmt/Forms/AddKeypool/AddKeypool";
import AddShoppingCart from "../../../components/NetworkMgmt/Forms/AddShoppingCart/AddShoppingCart";
import AddRealPage from "../../../components/NetworkMgmt/Forms/AddRealPage/AddRealPage";
import AddWebhook from "../../../components/NetworkMgmt/Forms/AddWebhook/AddWebhook";
import Transactions from "../../../components/NetworkMgmt/Transactions/Transactions";
import KeypoolInfo from "../../../components/NetworkMgmt/Settings/KeypoolInfo";
import {
	CountryDropdown,
	RegionDropdown,
} from "vault-react-country-region-selector";
import "react-phone-number-input/style.css";
import PhoneInput, {
	isPossiblePhoneNumber,
	isValidPhoneNumber,
} from "react-phone-number-input";
import _ from "lodash";
import countryInfo from "country-locale-map";
import getSymbolFromCurrency from 'currency-symbol-map'
import JSONPretty from 'react-json-pretty';
class OperatorInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			operatorInfo: undefined,
			activeItem: "1",
			loading_info: false,
			modalAddServicePlan: false,
			modify_operator: false,
			disabled: sessionStorage.getItem("customerSupport") > 0 ? true : false
		};
	}
	componentDidMount() {
		this.getOperatorInfo();
		this.getLogs();
	}
	getOperatorInfo = () => {
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
		};
		this.setState({
			loading_info: true,
			operatorInfo: undefined,
			servicePlans: []
		});
		axios
			.post("/networkoperatorconsole/equipment/listOperatorDetails", body, {
				headers: {},
			})
			.then(
				(response) => {
					this.setState({
						operatorInfo: response.data,
						domainAdminList: response.data["DomainAdministrators"],
						siemAdminList: response.data["SIEMAdministrators"],
						accountMgrList: response.data["AccountManagers"],
						customerSupportList: response.data["CustomerSupport"],
						servicePlans: response.data["ServicePlans"],
						shoppingCart: response.data["ShoppingCart"],
						realPage: response.data["PMS"]["RealPage"],
						defaultVenueConfig: response.data["NetworkConfiguration"]["DefaultVenueConfiguration"],
						webhookURL: response.data["WebhookURL"],
						networkOperatorName: response.data["NetworkOperatorName"],
						address1: response.data["AddressLine1"],
						address2: response.data["AddressLine2"],
						city: response.data["City"],
						region: response.data["State"],
						country: response.data["Country.ISO3166A3"],
						postalcode: response.data["PostalCode"],
						email: response.data["EmailAddress"],
						phoneNumber: response.data["PhoneNumber"],
						domain: response.data["EmailDomain"],
						allowOverlapping: response.data["NetworkConfiguration"]["allowOverlappingSubscriptionWiredUnit"],
						pinpointID: response.data["Notifications"]["Pinpoint"]["PinpointID"],
						smsphoneNumber: response.data["Notifications"]["SNS"]["SMSConsolePhoneNumber"],
						shoppingCartShortURL: response.data["ShoppingCart"]["ShoppingCartShortURL"],
						currencySymbol: countryInfo.getCurrencyByAlpha3(response.data["Country.ISO3166A3"])
					});
					this.setState({ loading_info: false });
					this.getAdmins();
					this.getGlobalKeypools();
				},
				(error) => {
					this.setState({ loading_info: false });
				}
			);
	};
	toggleCheckboxValueAllowOverlapping = () => {
		this.setState({ allowOverlapping: !this.state.allowOverlapping });
	};
	changeHandler = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	changeHandlerPhone = (event) => {
		this.setState({ phoneNumber: event });
		var value = event
			? isPossiblePhoneNumber(event)
				? undefined
				: "Please provide a valid phone number"
			: "Phone number required";
		this.setState({ invalidPhone: value });
	};
	changeHandlerSMSPhone = (event) => {
		this.setState({ smsphoneNumber: event });
		var value = event
			? isPossiblePhoneNumber(event)
				? undefined
				: "Please provide a valid phone number"
			: "Phone number required";
		this.setState({ invalidPhone: value });
	};
	submitModifyOperator = (event) => {
		event.preventDefault();
		this.setState({ modify_operator: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			NetworkOperatorName: this.state.networkOperatorName,
			AddressLine1: this.state.address1,
			AddressLine2: this.state.address2,
			City: this.state.city,
			State: this.state.region,
			"Country.ISO3166A3": this.state.country,
			PostalCode: this.state.postalcode,
			EmailAddress: this.state.email,
			"PhoneNumber.E164": this.state.phoneNumber,
			EmailDomain: this.state.domain,
			allowOverlappingSubscriptionWiredUnit: this.state.allowOverlapping,
			"SMSConsolePhoneNumber.E164": this.state.smsphoneNumber
		};
		axios
			.post(
				"/networkoperatorconsole/equipment/configureNetworkOperator",
				body,
				{
					headers: {},
				}
			)
			.then(
				(response) => {
					toast.success("Operator Info Modified!", {
						closeButton: false,
					});
					this.getOperatorInfo();
					this.setState({ modify_operator: false });
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
					this.setState({ modify_operator: false });
				}
			);
	};
 
	getLogs = () => {
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
		};
		axios
			.post("/networkoperatorconsole/equipment/listOperatorLog", body, {
				headers: {},
			})
			.then((response) => {
				this.setState({
					operatorLog: response.data.log,
				});
			})
			.catch((error) => {
				this.setState({ error: true });
			});
	};
	getAdmins = () => {
		this.setState({ staffArrayAll: undefined });
		let staffArrayAll = [];
		let staffArrayDomain = [];
		let staffArraySEIM = [];
		let staffArrayAccountMgr = [];
		let staffArrayCustomerSupport = [];
		for (let i = 0; i < this.state.domainAdminList.length; i++) {
			staffArrayDomain[i] = {
				[this.state.domainAdminList[i]]: "Domain Administrator",
			};
		}
		for (let j = 0; j < this.state.siemAdminList.length; j++) {
			staffArraySEIM[j] = {
				[this.state.siemAdminList[j]]: "SIEM Administrator",
			};
		}
		for (let j = 0; j < this.state.accountMgrList.length; j++) {
			staffArrayAccountMgr[j] = {
				[this.state.accountMgrList[j]]: "Account Manager",
			};
		}
		for (let j = 0; j < this.state.customerSupportList.length; j++) {
			staffArrayCustomerSupport[j] = {
				[this.state.customerSupportList[j]]: "Customer Support",
			};
		}
		staffArrayAll = staffArrayDomain.concat(
			staffArraySEIM,
			staffArrayAccountMgr,
			staffArrayCustomerSupport
		);
		this.setState({ staffArrayAll: [...staffArrayAll] });
	};
	toggleAddDomainAdministrator = () => {
		this.setState({
			modalAddDomainAdministrator: !this.state.modalAddDomainAdministrator,
		});
	};
	submitAddDomainAdministrators = (
		email,
		firstName,
		lastName,
		language,
		role
	) => {
		this.setState({ add_domainadmin: true, staffArrayAll: undefined });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			EmailAddress: email,
			GivenName: firstName,
			FamilyName: lastName,
			locale: language,
			Role: role,
		};
		axios
			.post("/networkoperatorconsole/staff/enableStaffAccess", body, {
				headers: {},
			})
			.then(
				(response) => {
					toast.success("Added Staff!", {
						closeButton: false,
					});
					this.toggleAddDomainAdministrator();
					this.setState({ add_domainadmin: false });
					this.getOperatorInfo();
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
					this.toggleAddDomainAdministrator();
					this.setState({ add_domainadmin: false });
				}
			);
	};
	toggleConfirmDisable = (key) => {
		this.setState({
			toggleConfirm: true,
			modal: !this.state.modal,
			confirmEmail: key,
		});
	};
	toggleDisable = (email) => {
		this.setState({ staffArrayAll: undefined });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			EmailAddress: email,
		};
		axios
			.post("/networkoperatorconsole/staff/disableStaffAccess", body, {
				headers: {},
			})
			.then(
				(response) => {
					toast.success("Deleted Administrator", {
						closeButton: false,
					});
					this.setState({
						modal: !this.state.modal,
					});
					this.setState({ add_domainadmin: false });
					this.getOperatorInfo();
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
					this.setState({
						modal: !this.state.modal,
					});
					this.setState({ add_domainadmin: false });
				}
			);
	};
	toggleTab = (tab) => () => {
		if (this.state.activeItem !== tab) {
			this.setState({
				activeItem: tab,
			});
		}
	};
	toggleAddServicePlan = () => {
		this.setState({
			modalAddServicePlan: !this.state.modalAddServicePlan,
		});
	};
	submitAddServicePlan = (
		servicePlanName,
		servicePlanDescription,
		uploadKbps,
		downloadKbps,
		conDevices,
		sessionTimeout,
		maxRecurrance,
		maxTrialCycles,
		billingCycleHours,
		billingCyclePrice,
		billingCycleRecurrence,
		trialcycles,
		spimage,
		globallyavailable,
		externalServicePlanID,
		smsCode,
  unique_plan_id,
  typical_download_speed,
  typical_upload_speed,
  privacy_policy_url,
  network_management_policy_url,
  data_allowance_policy_url,
  bundle_discounts_url,
  customer_service_url
	) => {
		this.setState({ add_serviceplan: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			ServicePlanName: servicePlanName,
			ServicePlanDescription: servicePlanDescription,
			UploadKbps: uploadKbps,
			DownloadKbps: downloadKbps,
			ConcurrentDevices: conDevices,
			SessionTimeoutSecondsPerDevice: sessionTimeout,
			MaximumTrialCyclesPerSubscriberPerVenue: maxTrialCycles,
			MaximumFreeEnrollmentsPerSubscriberPerVenue: maxRecurrance,
			BillingCycleHours: billingCycleHours,
			"BillingCyclePrice.USD": billingCyclePrice === undefined ? 0 : billingCyclePrice * 100,
			BillingCycleRecurrence: billingCycleRecurrence,
			ServicePlanImageURL: spimage,
			GloballyAvailablePlan: globallyavailable,
			TrialCycles: trialcycles === undefined? 0: trialcycles,
			externalServicePlanID: externalServicePlanID,
			SMSCode: smsCode,
   "FCCLabel": {
    PlanID: unique_plan_id,
    TypicalUploadKbps: typical_upload_speed,
    TypicalDownloadKbps: typical_download_speed,
    PrivacyPolicyURL: privacy_policy_url,
    NetworkMgmtPolicyURL: network_management_policy_url,
    DataPolicyURL: data_allowance_policy_url,
    BundleDiscountURL: bundle_discounts_url,
    SupportURL: customer_service_url
   }
		};
		axios
			.post("/networkoperatorconsole/equipment/addServicePlan", body, {
				headers: {},
			})
			.then(
				(response) => {
					toast.success("Added Service Plan!", {
						closeButton: false,
					});
					this.toggleAddServicePlan();
					this.getOperatorInfo();
					this.setState({ add_serviceplan: false });
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
					this.toggleAddServicePlan();
					this.setState({ add_serviceplan: false });
				}
			);
	};
	toggleModifyServicePlan = () => {
		this.setState({
			modalModifyServicePlan: !this.state.modalModifyServicePlan,
		});
	};
	modifyServicePlan = (key, servicePlanID) => {
		this.setState({
			modalModifyServicePlan: !this.state.modalModifyServicePlan,
			servicePlanIDKey: key,
			servicePlanID: servicePlanID
		});
	};
	submitModifyServicePlan = (
		servicePlanName,
		servicePlanID,
		servicePlanDescription,
		uploadKbps,
		downloadKbps,
		conDevices,
		sessionTimeout,
		maxRecurrance,
		maxTrialCycles,
		billingCycleHours,
		billingCyclePrice,
		billingCycleRecurrence,
		trialCycles,
		spimage,
		globallyavailable,
		defaultForWiredUnits,
		defaultForLocalWireless,
		externalServicePlanID,
		smsCode
	) => {
		this.setState({ modify_serviceplan: true });
		let updateServicePlan = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			ServicePlanID: servicePlanID,
			ServicePlanName: servicePlanName,
			ServicePlanDescription: servicePlanDescription,
			UploadKbps: uploadKbps,
			DownloadKbps: downloadKbps,
			ConcurrentDevices: conDevices,
			SessionTimeoutSecondsPerDevice: sessionTimeout,
			MaximumTrialCyclesPerSubscriberPerVenue: maxTrialCycles,
			MaximumFreeEnrollmentsPerSubscriberPerVenue: maxRecurrance,
			BillingCycleHours: billingCycleHours,
			"BillingCyclePrice.USD": billingCyclePrice * 100,
			BillingCycleRecurrence: billingCycleRecurrence,
			ServicePlanImageURL: spimage,
			GloballyAvailablePlan: globallyavailable,
			TrialCycles: trialCycles,
			externalServicePlanID: externalServicePlanID,
			SMSCode: smsCode
		};
		let updateNetworkOperator = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess:
				defaultForWiredUnits===true?servicePlanID:"",
			DefaultServicePlanID_LocalWirelessAccess: defaultForLocalWireless===true?servicePlanID:"",
		}
		Promise.all([
	      axios.post(
	        "/networkoperatorconsole/equipment/configureServicePlan",
	        updateServicePlan,
	        { headers: {} }
	      ),
	      axios.post("/networkoperatorconsole/equipment/configureNetworkOperator", updateNetworkOperator, {
	        headers: {},
	      }),
	    ])
	      .then(async ([res1, res2]) => {
	        toast.success("Updated Service Plan!", {
	          closeButton: false,
	        });
	        this.toggleModifyServicePlan();
					this.getOperatorInfo();
					this.setState({ modify_serviceplan: false });
	      })
	      .catch((error) => {
	        toast.error(error.response.data.message, {
	          closeButton: false,
	        });
	        this.toggleModifyServicePlan();
					this.setState({ modify_serviceplan: false });
	      });
	};
 submitModifyServicePlanBroadbandLabel = (
  servicePlanID,
  unique_plan_id,
  typical_download_speed,
  typical_upload_speed,
  privacy_policy_url,
  network_management_policy_url,
  data_allowance_policy_url,
  bundle_discounts_url,
  customer_service_url
 ) => {
  this.setState({ modify_serviceplan: true });
  let body = {
   AccessToken: sessionStorage.getItem("AccessToken"),
   ServicePlanID: servicePlanID,
   "FCCLabel": {
    PlanID: unique_plan_id,
    TypicalUploadKbps: typical_upload_speed,
    TypicalDownloadKbps: typical_download_speed,
    PrivacyPolicyURL: privacy_policy_url,
    NetworkMgmtPolicyURL: network_management_policy_url,
    DataPolicyURL: data_allowance_policy_url,
    BundleDiscountURL: bundle_discounts_url,
    SupportURL: customer_service_url
   }
 };
 axios
   .post("/networkoperatorconsole/equipment/configureServicePlan", body, {
     headers: {},
   })
   .then(
     (response) => {
       toast.success(response.data.message, {
         closeButton: false,
       });
       this.toggleModifyServicePlan();
       this.getOperatorInfo();
       this.setState({ modify_serviceplan: false });
     },
     (error) => {
      console.log(error)
      // toast.error(error.response.data.message, {
      //  closeButton: false,
      // });
      this.toggleModifyServicePlan();
      this.setState({ modify_serviceplan: false });
     }
   );
 };
	toggleAddRadiusServer = () => {
		this.setState({
			modalAddRadiusServer: !this.state.modalAddRadiusServer,
		});
	};
	submitAddRadiusServer = (ipAddy, authPort, acctPort) => {
		this.setState({ add_radius: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			RadiusServerIP: ipAddy,
			AuthenticationPort: authPort,
			AccountingPort: acctPort,
		};
		axios
			.post("/networkoperatorconsole/equipment/addRADIUSServer", body, {
				headers: {},
			})
			.then(
				(response) => {
					toast.success("Added Radius Server!", {
						closeButton: false,
					});
					this.toggleAddRadiusServer();
					this.getOperatorInfo();
					this.setState({ add_radius: false });
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
					this.toggleAddRadiusServer();
					this.setState({ add_radius: false });
				}
			);
	};
	toggleConfirmRemoveRadiusServer = (key) => {
		this.setState({
			modalConfirmRemoveRadiusServer:
				!this.state.modalConfirmRemoveRadiusServer,
		});
	};
	removeRadiusServerConfirm = (key) => {
		this.toggleConfirmRemoveRadiusServer();
		this.setState({
			radiusIpAddy: key,
		});
	};
	removeRadiusServer = () => {
		this.setState({ add_radius: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			RadiusServerIP: this.state.radiusIpAddy,
		};
		axios
			.post("/networkoperatorconsole/equipment/removeRADIUSServer", body, {
				headers: {},
			})
			.then(
				(response) => {
					toast.success("Removed Radius Server!", {
						closeButton: false,
					});
					this.getOperatorInfo();
					this.toggleConfirmRemoveRadiusServer();
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
				}
			);
	};
	toggleAddWifiController = () => {
		this.setState({
			modalAddWifiController: !this.state.modalAddWifiController,
		});
	};
	submitAddWifiController = (ipAddy) => {
		this.setState({ add_wifi: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			"NAS-IP-Address": ipAddy,
		};
		axios
			.post("/networkoperatorconsole/equipment/addWiFiController", body, {
				headers: {},
			})
			.then(
				(response) => {
					toast.success("Added Wifi Controller!", {
						closeButton: false,
					});
					this.toggleAddWifiController();
					this.getOperatorInfo();
					this.setState({ add_wifi: false });
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
					this.toggleAddWifiController();
					this.setState({ add_wifi: false });
				}
			);
	};
	toggleConfirmRemoveWifiController = (key) => {
		this.setState({
			modalConfirmRemoveWifiController:
				!this.state.modalConfirmRemoveWifiController,
		});
	};
	removeWifiControllerConfirm = (key) => {
		this.toggleConfirmRemoveWifiController();
		this.setState({
			NASIpAddy: key,
		});
	};
	removeWifiController = () => {
		this.setState({ add_radius: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			"NAS-IP-Address": this.state.NASIpAddy,
		};
		axios
			.post("/networkoperatorconsole/equipment/removeWiFiController", body, {
				headers: {},
			})
			.then(
				(response) => {
					toast.success("Removed Wifi Controller!", {
						closeButton: false,
					});
					this.getOperatorInfo();
					this.toggleConfirmRemoveWifiController();
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
				}
			);
	};
	getGlobalKeypools = () => {
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
		};
		this.setState({
			keypoolList: undefined,
		});
		axios
			.post("/networkoperatorconsole/keypools/listKeypools", body, {
				headers: {},
			})
			.then(
				(response) => {
					var sortedKeys = _(response.data.myKeyPoolList)
						.map(function (v, k) {
							// insert the key into the object
							return _.merge({}, v, { key: k });
						})
						.sortBy("DisplayOrder") // sort by name
						.value();
					this.setState({
						keypoolList: sortedKeys,
					});
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
				}
			);
	};
	toggleAddKeypool = () => {
		this.setState({
			modalAddKeypool: !this.state.modalAddKeypool,
		});
	};
	submitAddKeypool = (ssid, venueID, scope) => {
		this.setState({ add_keypool: true });
		let body;
		if(scope === "global"){
			body = {
				AccessToken: sessionStorage.getItem("AccessToken"),
				SSID: ssid,
				Scope: scope
			};
		}else{
			body = {
				AccessToken: sessionStorage.getItem("AccessToken"),
				SSID: ssid,
				VenueID: venueID
			};
		}
		axios
			.post("/networkoperatorconsole/keypools/addKeypool", body, {
				headers: {},
			})
			.then(
				(response) => {
					toast.success("Added Keypool!", {
						closeButton: false,
					});
					this.toggleAddKeypool();
					this.props.updateName(this.props.venueName, this.props.venueID);
					this.setState({ add_keypool: false });
					this.getGlobalKeypools();
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
					this.toggleAddKeypool();
					this.setState({ add_keypool: false });
				}
			);
	};
	toggleKeypool = (key) => {
		this.setState({
			modalViewKeypool: !this.state.modalViewKeypool,
			keyPoolID: key,
		});
	};
	toggleKeypoolInfo = () => {
		this.setState({
			modalViewKeypool: !this.state.modalViewKeypool,
		});
	};
	modifyVenueAddress = (
		address1,
		address2,
		phoneNumber,
		country,
		city,
		region,
		zipcode,
		sharedSecret
	) => {
		if (address2 === undefined) {
			address2 = "";
		}
		this.setState({ loading: true });
		this.setState({ reloadVenueList: true });
		const data = {
			VenueID: sessionStorage.getItem("venueID"),
			AddressLine1: address1,
			AddressLine2: address2,
			City: city,
			State: region,
			PostalCode: zipcode,
			"Country.ISO3166A3": country,
			PhoneNumber: phoneNumber,
			SharedSecret: sharedSecret,
			AccessToken: sessionStorage.getItem("AccessToken"),
		};
		axios
			.post("/networkoperatorconsole/venues/updateVenueAddress", data, {
				headers: {},
			})
			.then(
				(response) => {
					this.props.updateName(
						sessionStorage.getItem("venueName"),
						sessionStorage.getItem("venueID")
					);
					toast.success("Updated Venue Address!", {
						closeButton: false,
					});
					this.setState({ loading: false });
					this.setState({ reloadVenueList: false });
				},
				(error) => {
					toast.error("There was an error", {
						closeButton: false,
					});
					this.setState({ loading: false });
					this.setState({ reloadVenueList: false });
				}
			);
	};
	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};
	toggleAddShoppingCart = () => {
		this.setState({
			modalAddShoppingCart: !this.state.modalAddShoppingCart,
		});
	};
	submitAddShoppingCart = (
		enabled,
		publishableKey,
		secretKey,
		signingSecret,
		refund,
		shoppingCartShortURL
	) => {
		this.setState({ add_shopping_cart: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			enableShoppingCart: enabled,
			StripePublishableKey: publishableKey,
			Stripe_Secret_key: secretKey,
			Stripe_Webhook_signingsecret: signingSecret,
			Stripe_allowrefund: refund,
			ShoppingCartShortURL: shoppingCartShortURL
		};
		axios
			.post(
				"/networkoperatorconsole/equipment/configureNetworkOperator",
				body,
				{
					headers: {},
				}
			)
			.then(
				(response) => {
					toast.success("Updated Shopping Cart!", {
						closeButton: false,
					});
					sessionStorage.setItem("cartEnabledGlobally", enabled)
					this.toggleAddShoppingCart();
					this.getOperatorInfo();
					this.setState({ add_shopping_cart: false });
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
					this.toggleAddShoppingCart();
					this.setState({ add_shopping_cart: false });
				}
			);
	};
	toggleAddRealPage = () => {
		this.setState({
			modalAddRealPage: !this.state.modalAddRealPage,
		});
	};
	submitAddRealPage = (realpageurl, realpageapikey) => {
		this.setState({ add_realpage: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			RealPageURL: realpageurl,
			RealPageAPIKey: realpageapikey
		};
		axios
			.post(
				"/networkoperatorconsole/equipment/configureNetworkOperator",
				body,
				{
					headers: {},
				}
			)
			.then(
				(response) => {
					toast.success("Added RealPage!", {
						closeButton: false,
					});
					this.toggleAddRealPage();
					this.getOperatorInfo();
					this.setState({ add_realpage: false });
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
					this.toggleAddRealPage();
					this.setState({ add_realpage: false });
				}
			);
	};
	toggleAddWebhook = () => {
		this.setState({
			modalAddWebhook: !this.state.modalAddWebhook,
		});
	};
	submitAddWebhook = (webhookurl) => {
		this.setState({ add_webhook: true });
		let body = {
			AccessToken: sessionStorage.getItem("AccessToken"),
			WebhookURL: webhookurl
		};
		axios
			.post(
				"/networkoperatorconsole/equipment/configureNetworkOperator",
				body,
				{
					headers: {},
				}
			)
			.then(
				(response) => {
					toast.success("Added Webhook URL!", {
						closeButton: false,
					});
					this.toggleAddWebhook();
					this.getOperatorInfo();
					this.setState({ add_webhook: false });
				},
				(error) => {
					toast.error("There was an error!", {
						closeButton: false,
					});
					this.toggleAddWebhook();
					this.setState({ add_webhook: false });
				}
			);
	};
 
	render() {
		const { t } = this.props;
		const FORMAT = "MM/dd/yyyy";
		return (
			<>
				{this.state.toggleConfirm ? (
					<MDBModal isOpen={this.state.modal} toggle={this.toggle}>
						<MDBModalHeader toggle={this.toggle}>
							Confirm Disable
						</MDBModalHeader>
						<MDBModalBody>
							<p>Email: {this.state.confirmEmail}</p>
						</MDBModalBody>
						<MDBModalFooter>
							<MDBBtn
								color="primary"
								onClick={() => this.toggleDisable(this.state.confirmEmail)}
							>
								Confirm
							</MDBBtn>
							<MDBBtn color="primary" onClick={this.toggle}>
								Close
							</MDBBtn>
						</MDBModalFooter>
					</MDBModal>
				) : null}
				<MDBModal
					isOpen={this.state.modalAddDomainAdministrator}
					toggle={this.toggle}
					size="lg"
					className="form"
					centered
				>
					<AddDomainAdministrator
						submitAddDomainAdministrators={this.submitAddDomainAdministrators}
						toggleAddDomainAdministrator={this.toggleAddDomainAdministrator}
						add_domainadmin={this.state.add_domainadmin}
						loading={this.state.loading}
						updateName={this.props.updateName}
					/>
				</MDBModal>
				<MDBModal
					isOpen={this.state.modalAddRadiusServer}
					toggle={this.toggle}
					size="lg"
					className="form"
					centered
				>
					<AddRadiusServer
						toggleAddRadiusServer={this.toggleAddRadiusServer}
						submitAddRadiusServer={this.submitAddRadiusServer}
						add_radius={this.state.add_serviceplan}
					/>
				</MDBModal>
				<MDBModal
					isOpen={this.state.modalAddServicePlan}
					toggle={this.toggle}
					size="fluid"
					className="form"
					centered
				>
					<AddServicePlan
						toggleAddServicePlan={this.toggleAddServicePlan}
						submitAddServicePlan={this.submitAddServicePlan}
						add_serviceplan={this.state.add_serviceplan}
					/>
				</MDBModal>
				<MDBModal
					isOpen={this.state.modalModifyServicePlan}
					toggle={this.toggle}
					size="fluid"
					className="form"
					centered
				>
					<ModifyServicePlan
						toggleModifyServicePlan={this.toggleModifyServicePlan}
						submitModifyServicePlan={this.submitModifyServicePlan}
      submitModifyServicePlanBroadbandLabel={this.submitModifyServicePlanBroadbandLabel}
						modify_serviceplan={this.state.modify_serviceplan}
						servicePlanIDKey={this.state.servicePlanIDKey}
						servicePlanID={this.state.servicePlanID}
						servicePlans={this.state.servicePlans}
						defaultVenueConfig={this.state.defaultVenueConfig}
						currencyName={this.state.currencyName}
					/>
				</MDBModal>
				<MDBModal
					isOpen={this.state.modalConfirmRemoveRadiusServer}
					toggle={this.toggleConfirmRemoveRadiusServer}
				>
					<MDBModalHeader toggle={this.toggleConfirmRemoveRadiusServer}>
						Confirm Remove Radius Server
					</MDBModalHeader>
					<MDBModalBody>
						<p>IP Address: {this.state.radiusIpAddy}</p>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn color="primary" onClick={this.removeRadiusServer}>
							Confirm
						</MDBBtn>
						<MDBBtn
							color="primary"
							onClick={this.toggleConfirmRemoveRadiusServer}
						>
							Close
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
				<MDBModal
					isOpen={this.state.modalConfirmRemoveWifiController}
					toggle={this.toggleConfirmRemoveWifiController}
				>
					<MDBModalHeader toggle={this.toggleConfirmRemoveWifiController}>
						Confirm Remove Wifi Controller
					</MDBModalHeader>
					<MDBModalBody>
						<p>IP Address: {this.state.NASIpAddy}</p>
					</MDBModalBody>
					<MDBModalFooter>
						<MDBBtn color="primary" onClick={this.removeWifiController}>
							Confirm
						</MDBBtn>
						<MDBBtn
							color="primary"
							onClick={this.toggleConfirmRemoveWifiController}
						>
							Close
						</MDBBtn>
					</MDBModalFooter>
				</MDBModal>
				<MDBModal
					isOpen={this.state.modalViewKeypool}
					toggle={this.toggleKeypool}
					size="lg"
					className="form"
					centered
				>
					<KeypoolInfo
						keypoolInfo={this.state.keypoolList}
						toggleKeypoolInfo={this.toggleKeypoolInfo}
						keyPoolID={this.state.keyPoolID}
						modalViewKeypoolInfo={this.state.modalViewKeypoolInfo}
						handleSelectChangeVenueFromList={
							this.props.handleSelectChangeVenueFromList
						}
						getVenueDetails={this.props.getVenueDetails}
						getVenues={this.props.getVenues}
						attributeForm={this.props.attributeForm}
						venueInfo={this.props.venueInfo}
						unitsArrayAll={this.props.unitsArrayAll}
						keyPoolList={this.props.keyPoolList}
						servicePlans={this.props.servicePlans}
						staffArrayAll={this.props.staffArrayAll}
						venueEquipList={this.props.venueEquipList}
						updateName={this.props.updateName}
						sharedSecret={this.props.sharedSecret}
						modifyVenueAddress={this.modifyVenueAddress}
						getOperatorInfo={this.getOperatorInfo}
						getGlobalKeypools={this.getGlobalKeypools}
						venueID={this.props.venueID}
					/>
				</MDBModal>
				<MDBModal
					isOpen={this.state.modalAddKeypool}
					toggle={this.toggle}
					size="lg"
					className="form"
					centered
				>
					<AddKeypool
						toggleAddKeypool={this.toggleAddKeypool}
						submitAddKeypool={this.submitAddKeypool}
						add_keypool={this.state.add_keypool}
						globalKey={true}
						attributeForm={this.props.attributeForm}
						venueID={this.props.venueID}
					/>
				</MDBModal>
				<MDBModal
					isOpen={this.state.modalAddShoppingCart}
					toggle={this.toggleAddShoppingCart}
					size="lg"
					className="form"
					centered
				>
					<AddShoppingCart
						toggleAddShoppingCart={this.toggleAddShoppingCart}
						submitAddShoppingCart={this.submitAddShoppingCart}
						add_shopping_cart={this.state.add_shopping_cart}
						shoppingCart={this.state.shoppingCart}
						shoppingCartShortURL={this.state.shoppingCartShortURL}
					/>
				</MDBModal>
				<MDBModal
					isOpen={this.state.modalAddRealPage}
					toggle={this.toggleAddRealPage}
					size="lg"
					className="form"
					centered
				>
					<AddRealPage
						toggleAddRealPage={this.toggleAddRealPage}
						submitAddRealPage={this.submitAddRealPage}
						add_realpage={this.state.add_realpage}
						realPage={this.state.realPage}
					/>
				</MDBModal>
				<MDBModal
					isOpen={this.state.modalAddWebhook}
					toggle={this.toggleAddWebhook}
					size="lg"
					className="form"
					centered
				>
					<AddWebhook
						toggleAddWebhook={this.toggleAddWebhook}
						submitAddWebhook={this.submitAddWebhook}
						add_webhook={this.state.add_webhook}
						webhookURL={this.state.webhookURL}
					/>
				</MDBModal>
				{this.state.loading_info === true ? (
					<div className="overlay" id="overlay" style={{ display: "block" }}>
						<div className="overlayMessage">
							<div className="spinner-border spinner-border" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				<MDBModalHeader toggle={this.props.toggleOperatorInfo}>
					<h4>Operator Information</h4>
					{this.state.activeItem === "1" ? (
						<p>{t("addnewresident.required")}</p>
					) : (
						""
					)}
				</MDBModalHeader>
				<MDBModalBody>
					<MDBNav className="nav-tabs">
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === "1"}
								onClick={this.toggleTab("1")}
								role="tab"
							>
								<MDBIcon icon="user" /> Profile
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === "2"}
								onClick={this.toggleTab("2")}
								role="tab"
							>
								<MDBIcon icon="user-shield" /> Administrators
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === "3"}
								onClick={this.toggleTab("3")}
								role="tab"
							>
								<MDBIcon icon="signal" /> Service Plans
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === "4"}
								onClick={this.toggleTab("4")}
								role="tab"
							>
								<MDBIcon icon="server" /> Radius Servers
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === "6"}
								onClick={this.toggleTab("6")}
								role="tab"
							>
								<MDBIcon icon="key" /> Keypools
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === "7"}
								onClick={this.toggleTab("7")}
								role="tab"
							>
								<MDBIcon icon="shopping-cart" /> Shopping Cart
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === "8"}
								onClick={this.toggleTab("8")}
								role="tab"
							>
								<MDBIcon icon="home" /> RealPage
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === "9"}
								onClick={this.toggleTab("9")}
								role="tab"
							>
								<MDBIcon icon="globe" /> Webhook
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === "10"}
								onClick={this.toggleTab("10")}
								role="tab"
							>
								<MDBIcon icon="clipboard-list" /> Logs
							</MDBNavLink>
						</MDBNavItem>
					</MDBNav>
					<MDBTabContent className="" activeItem={this.state.activeItem}>
						{this.state.operatorInfo !== undefined ? (
							<>
								<MDBTabPane tabId="1" role="tabpanel">
								<form
									className="needs-validation"
									onSubmit={this.submitModifyOperator}
									noValidate
								>
									<MDBRow>
										<MDBCol col="6">
											<label
												htmlFor="defaultFormLoginEmailEx"
												className="black-text"
											>
												Operator Name
											</label>
											<input
												id="networkOperatorName"
												name="networkOperatorName"
												className="form-control"
												onChange={this.changeHandler}
												value={this.state.networkOperatorName}
												// disabled={this.state.disabled}
                        disabled={true}
											/>
											<div className="invalid-feedback">
												Please provide a valid name.
											</div>
										</MDBCol>
										<MDBCol col="6"></MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol col="6">
											<label
												htmlFor="defaultFormLoginEmailEx"
												className="black-text"
											>
												Address 1
											</label>
											<input
												id="address1"
												name="address1"
												className="form-control"
												onChange={this.changeHandler}
												value={this.state.address1}
												disabled={this.state.disabled}
											/>
											<div className="invalid-feedback">
												Please provide a valid value.
											</div>
										</MDBCol>
										<MDBCol col="6">
											<label
												htmlFor="defaultFormLoginEmailEx"
												className="black-text"
											>
												Address 2
											</label>
											<input
												id="address2"
												name="address2"
												className="form-control"
												onChange={this.changeHandler}
												value={this.state.address2}
												disabled={this.state.disabled}
											/>
											<div className="invalid-feedback">
												Please provide a valid value.
											</div>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol col="6">
											<label
												htmlFor="defaultFormLoginEmailEx"
												className="black-text"
											>
												City
											</label>
											<input
												id="city"
												name="city"
												className="form-control"
												onChange={this.changeHandler}
												value={this.state.city}
												disabled={this.state.disabled}
											/>
											<div className="invalid-feedback">
												Please provide a valid value.
											</div>
										</MDBCol>
										<MDBCol col="6">
											<label
													htmlFor="defaultFormLoginEmailEx"
													className="black-text"
												>
													State*
												</label>
												<div className="select-box">
												<RegionDropdown
													country={this.state.country}
													value={this.state.region}
													onChange={(val) =>
														this.selectRegion(val)
													}
													valueType="short"
													className="form-control"
													id="selectState"
													disabled={this.state.disabled}
												/>
												</div>
												{this.state.invalidState !==
												null ? (
													<div className="invalid">
														{
															this.state
																.invalidState
														}
													</div>
												) : (
													""
												)}
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol col="6">
											<label
												htmlFor="defaultFormLoginEmailEx"
												className="black-text"
											>
												Postal Code
											</label>
											<input
												id="postalcode"
												name="postalcode"
												className="form-control"
												onChange={this.changeHandler}
												value={this.state.postalcode}
												disabled={this.state.disabled}
											/>
											<div className="invalid-feedback">
												Please provide a valid value.
											</div>
										</MDBCol>
										<MDBCol col="6">
											<label
													htmlFor="defaultFormLoginEmailEx"
													className="black-text"
												>
													Country*
												</label>
												<div className="select-box">
												<CountryDropdown
													value={this.state.country}
													onChange={(val) =>
														this.selectCountry(val)
													}
													priorityOptions={[
														"CAN",
														"USA",
														"GBR",
													]}
													valueType="short"
													countryvaluetype="short"
													className="form-control"
													id="selectCountry"
													disabled={this.state.disabled}
												/>
												</div>
												{this.state.invalidCountry !==
												null ? (
													<div className="invalid">
														{
															this.state
																.invalidCountry
														}
													</div>
												) : (
													""
												)}
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol col="6">
											<label
												htmlFor="defaultFormLoginEmailEx"
												className="black-text"
											>
												Email
											</label>
											<input
												id="email"
												name="email"
												className="form-control"
												onChange={this.changeHandler}
												value={this.state.email}
												disabled={this.state.disabled}
											/>
											<div className="invalid-feedback">
												Please provide a valid value.
											</div>
										</MDBCol>
										<MDBCol col="6">
											<label
												htmlFor="defaultFormLoginEmailEx"
												className="black-text"
											>
												Phone Number
											</label>
											<PhoneInput
												placeholder={t(
													"addnewresident.enterphone"
												)}
												value={this.state.phoneNumber}
												format={FORMAT}
												onChange={this.changeHandlerPhone}
												name="phoneNumber"
												id="phoneNumber"
												className={
													this.state.loading === true
														? "form-control loading"
														: "form-control"
												}
												defaultCountry="US"
												required
												error={
													this.state.phoneNumber
														? isValidPhoneNumber(
																this.state
																	.phoneNumber
														  )
															? undefined
															: "Invalid phone number"
														: "Phone number required"
												}
												disabled={this.state.disabled}
											/>
											<div className="invalid-feedback">
												Please provide a valid value.
											</div>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol col="6">
											<label
												htmlFor="defaultFormLoginEmailEx"
												className="black-text"
											>
												SMS Console Phone Number
											</label>
											<PhoneInput
												placeholder={t(
													"addnewresident.enterphone"
												)}
												value={this.state.smsphoneNumber}
												format={FORMAT}
												onChange={this.changeHandlerSMSPhone}
												name="smsphoneNumber"
												id="smsphoneNumber"
												className={
													this.state.loading === true
														? "form-control loading"
														: "form-control"
												}
												defaultCountry="US"
												required
												error={
													this.state.smsphoneNumber
														? isValidPhoneNumber(
																this.state
																	.smsphoneNumber
														  )
															? undefined
															: "Invalid phone number"
														: "Phone number required"
												}
												disabled={this.state.disabled}
											/>
											<div className="invalid-feedback">
												Please provide a valid value.
											</div>
										</MDBCol>
										<MDBCol col="6">
											<label
												htmlFor="defaultFormLoginEmailEx"
												className="black-text"
											>
												Email Domain
											</label>
											<input
												id="domain"
												name="domain"
												className="form-control"
												onChange={this.changeHandler}
												value={this.state.domain}
												disabled={this.state.disabled}
											/>
											<div className="invalid-feedback">
												Please provide a valid value.
											</div>
										</MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol col="6">
										<MDBInput 
											label="Allow Overlapping Subscriptions" 
											type="checkbox" 
											id="allowOverlapping" 
											name="allowOverlapping" 
											onChange={this.toggleCheckboxValueAllowOverlapping} 
											checked={this.state.allowOverlapping}
											disabled={this.state.disabled}
										>
                    <MDBTooltip material placement="left" className="rightTooltip" style={{ display: "block" }}>
                                    <MDBIcon far icon="question-circle" />
                                    <span>
                                        Allow multiple subscribers to sign up for service in the same unit. If enabled, a temporary unit on a dynamic VLAN from the Residential Devices Range will be created while the subscription is overlapping.
                                    </span>
                                </MDBTooltip>
                                </MDBInput>
										</MDBCol>
										<MDBCol col="6">
										</MDBCol>
									</MDBRow>
									<br />
									<MDBRow>
										<MDBCol col="6">
											<p>
												<strong>Supported Locales</strong>
											</p>
											<OperatorSupportedLocalesTable
												supportedLocalesData={
													this.state.operatorInfo["SupportedLocales"]
												}
											/>
										</MDBCol>
									</MDBRow>
									<div className="text-right submitButton">
									<MDBBtn 
										color="danger" 
										type="submit"
										disabled={this.state.disabled}
									>
										{this.state.modify_operator === true ? (
											<div
												className="spinner-border spinner-border-sm"
												role="status"
											>
												<span className="sr-only">
													Loading...
												</span>
											</div>
										) : (
											"Save Changes"
										)}
									</MDBBtn>
									</div>
									</form>
								</MDBTabPane>
								<MDBTabPane tabId="2" role="tabpanel">
									{this.state.staffArrayAll !== undefined ? (
										<>
											<div className="servicePlans">
												<OperatorAdministratorsTable
													administratorsData={this.state.staffArrayAll}
													toggleDisable={this.toggleConfirmDisable}
												/>
											</div>
										</>
									) : (
										<MDBSpinner small />
									)}
									<MDBBtn
										color="primary"
										className="btn-right"
										onClick={this.toggleAddDomainAdministrator}
										disabled={this.state.disabled}
									>
										Add Administrator
									</MDBBtn>
								</MDBTabPane>
								<MDBTabPane tabId="3" role="tabpanel">
									<OperatorServicePlansTable
										servicePlanData={this.state.operatorInfo["ServicePlans"]}
										modifyServicePlan={this.modifyServicePlan}
										defaultVenueConfig={this.state.operatorInfo["NetworkConfiguration"]["DefaultVenueConfiguration"]}
										currencySymbol={getSymbolFromCurrency(this.state.currencySymbol)}
										currencyName={this.state.currencySymbol}
									/>
									<MDBBtn
										color="primary"
										className="btn-right"
										onClick={this.toggleAddServicePlan}
										disabled={this.state.disabled}
									>
										Add Service Plan
									</MDBBtn>
								</MDBTabPane>
								<MDBTabPane tabId="4" role="tabpanel">
									<OperatorRadiusServerTable
										radiusServerData={
											this.state.operatorInfo["NetworkConfiguration"]["Radius"]
										}
										removeRadiusServerConfirm={this.removeRadiusServerConfirm}
									/>
									<MDBRow>
										<MDBCol col="6">
											<p>
												<strong>Authentication Port</strong>
											</p>
											<p>
												{
													this.state.operatorInfo["NetworkConfiguration"]["Radius"][
														"AuthenticationPort"
													]
												}
											</p>
										</MDBCol>
										<MDBCol col="6">
											<p>
												<strong>Accounting Port</strong>
											</p>
											<p>
												{this.state.operatorInfo["NetworkConfiguration"]["Radius"]["AccountingPort"]}
											</p>
										</MDBCol>
									</MDBRow>
									<br />
									<MDBBtn
										color="primary"
										className="btn-right"
										onClick={this.toggleAddRadiusServer}
										disabled={this.state.disabled}
									>
										Add Radius Server
									</MDBBtn>
								</MDBTabPane>
								{/* <MDBTabPane tabId="5" role="tabpanel">
									<OperatorWiFiControllerTable
										wifiControllerData={
											this.state.operatorInfo["Wi-FiController"][
												"NAS-IP-Address"
											]
										}
										removeWifiControllerConfirm={
											this.removeWifiControllerConfirm
										}
									/>
									<MDBBtn
										color="primary"
										className="btn-right"
										onClick={this.toggleAddWifiController}
									>
										Add Wifi Controller
									</MDBBtn>
								</MDBTabPane> */}
								<MDBTabPane tabId="6" role="tabpanel">
									{this.state.keypoolList !== undefined ? (
										<>
											<OperatorKeypoolsTable
												operatorKeypoolList={this.state.keypoolList}
												getKeypoolInfo={this.toggleKeypool}
												modalVieKeypool={this.state.modalVieKeypool}
											/>
											<MDBBtn
												color="primary"
												className="btn-right"
												onClick={this.toggleAddKeypool}
												disabled={this.state.disabled}
											>
												Add Keypool
											</MDBBtn>
										</>
									) : (
										<MDBSpinner small />
									)}
								</MDBTabPane>
								<MDBTabPane tabId="7" role="tabpanel">
									<MDBRow>
										<MDBCol col="12">
											<p>
												<strong>Cart Enabled/Disabled</strong>
											</p>
											<p className="breakText">
												{this.state.shoppingCart["enableShoppingCart"] === true
													? "Enabled"
													: "Disabled"}
											</p>
										</MDBCol>
									</MDBRow>
									<br />
									<MDBRow>
										<MDBCol col="12">
											<p>
												<strong>Issue Refund By Default</strong>
											</p>
											<p className="breakText">
												{this.state.shoppingCart["Stripe"]["Stripe_allowrefund"] === true
													? "Enabled"
													: "Disabled"}
											</p>
										</MDBCol>
									</MDBRow>
									<br />
									<MDBRow>
										<MDBCol col="12">
											<p>
												<strong>Stripe Publishable Key</strong>
											</p>
											<p className="breakText">
												{this.state.shoppingCart["Stripe"]["StripePublishableKey"]}
											</p>
										</MDBCol>
									</MDBRow>
									<br />
									<MDBRow>
										<MDBCol col="12">
											<p>
												<strong>Stripe Webhook URL</strong>
											</p>
											<p className="breakText">
												{this.state.shoppingCart["Stripe"]["StripeWebHookURL"]}
											</p>
										</MDBCol>
									</MDBRow>
									<br />
									<MDBRow>
										<MDBCol col="12">
											<p>
												<strong>Shopping Cart Short URL</strong>
											</p>
											<p className="breakText">
												{this.state.shoppingCartShortURL}
											</p>
										</MDBCol>
									</MDBRow>
									<Transactions
										currencyName={this.state.currencySymbol}
									/>
									<br />
									<MDBBtn
										color="primary"
										className="btn-right"
										onClick={this.toggleAddShoppingCart}
										disabled={this.state.disabled}
									>
										{this.state.shoppingCart["Stripe"]["StripePublishableKey"] !==
										undefined
											? "Modify Shopping Cart"
											: "Add Shopping Cart"}
									</MDBBtn>
								</MDBTabPane>
								<MDBTabPane tabId="8" role="tabpanel">
									<MDBRow>
										<MDBCol col="12">
											<p>
												<strong>RealPage URL</strong>
											</p>
											<p className="breakText">
												{this.state.realPage["RealPageURL"]}
											</p>
										</MDBCol>
									</MDBRow>
									<br />
									<MDBRow>
										<MDBCol col="12">
											<p>
												<strong>RealPage API Key</strong>
											</p>
											<p className="breakText">
												{this.state.realPage["RealPageAPIKey"]}
											</p>
										</MDBCol>
									</MDBRow>
									<MDBBtn
										color="primary"
										className="btn-right"
										onClick={this.toggleAddRealPage}
										disabled={this.state.disabled}
									>
										{this.state.realPage["RealPageAPIKey"] !== undefined
											? "Save Changes"
											: "Add RealPage"}
									</MDBBtn>
								</MDBTabPane>
								<MDBTabPane tabId="9" role="tabpanel">
									<MDBRow>
										<MDBCol col="12">
											<p>
												<strong>Webhook URL</strong>
											</p>
											<p className="breakText">
												{this.state.webhookURL}
											</p>
										</MDBCol>
									</MDBRow>
									<MDBBtn
										color="primary"
										className="btn-right"
										onClick={this.toggleAddWebhook}
										disabled={this.state.disabled}
									>
										{this.state.webhookURL !== undefined
											? "Save Changes"
											: "Add Webhook"}
									</MDBBtn>
								</MDBTabPane>
								<MDBTabPane tabId="10" role="tabpanel">
									<MDBRow>
										<MDBCol col="12">
										{this.state.operatorLog !== undefined ? (
								// console.log(this.state.subscriberLog)
								<div>
									<JSONPretty id="json-pretty" data={this.state.operatorLog}></JSONPretty>
								</div>
								// <div>
								// 	{
								// 		this.state.operatorLog.map((value, index) => (
								// 			<div className="mt-2 mb-2" key={index}>
								// 				{
								// 					Object.entries(value).map(
								// 						(
								// 							(value2, index2) =>
								// 							(
								// 								value2[0] !== "event" ?
								// 									<><p className="mb-0"><strong>{value2[0]}: </strong>{value2[1]}</p></>
								// 									:
								// 									Object.entries(value2[1]).map(
								// 										(
								// 											(value3, index3) =>
								// 											(
								// 												<><p className="mb-0"><strong>{value3[0]}: </strong>{value3[1]}</p></>
								// 											)
								// 										)
								// 									)
								// 							)
								// 						)
								// 					)
								// 				}
								// 				<hr />
								// 			</div>
								// 		)
								// 		)
								// 	}
								// </div>
							) : (
								<div
									className="spinner-border spinner-border-sm"
									role="status"
								>
									<span className="sr-only">Loading...</span>
								</div>
							)}
										</MDBCol>
									</MDBRow>
								</MDBTabPane>
							</>
						) : (
							""
						)}
					</MDBTabContent>
				</MDBModalBody>
				<MDBModalFooter>
					<MDBBtn color="primary" onClick={this.props.toggleOperatorInfo}>
						Ok
					</MDBBtn>
				</MDBModalFooter>
			</>
		);
	}
}
export default withTranslation()(OperatorInfo);