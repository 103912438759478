import React, { Component } from "react";
import Chart from "react-apexcharts";
import axios from "../../../../axios";
// import { withTranslation } from "react-i18next";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            options1: undefined,
            selectedKeyID: this.props.selectedKeyID,
            category: [],
            bandwidthData: [],
            series: [],
        };
    }
    componentDidMount() {
        this.getBandwidthData(this.props.selectedKeyID);
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.selectedKeyID !== this.state.selectedKeyID) {
            this.setState({ selectedKeyID: nextProps.selectedKeyID });
            this.getBandwidthData(nextProps.selectedKeyID);
        }
    }
    getBandwidthData = (keyID) => {
        this.setState({ loading: true });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            KeyID: keyID,
        };
        axios
            .post("/subscriberconsole/listKeySessions", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    bandwidthData: response.data.UsageData,
                });
                if (this.state.bandwidthData !== undefined) {
                    this.setSeries();
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.getBandwidthData(keyID);
                this.setState({ error: true });
                this.setState({ loading: false });
            });
    };
    setBandwidthData = () => {
        this.setState({
            name: [],
        });
        let unique_name = {};
        for (var i = 0; i < this.state.bandwidthData.length; i++) {
            for (
                var j = 0;
                j <
                Object.keys(this.state.bandwidthData[i]["Calling-Station-Id"])
                    .length;
                j++
            ) {
                unique_name[
                    this.state.bandwidthData[i]["Calling-Station-Id"][j]
                ] = {};
                if (this.state.bandwidthData !== []) {
                    this.setSeries(unique_name);
                }
            }
        }
    };
    setSeries = (unique_name) => {
        this.setState({ loading: true });
        let option_categories = [];
        let data = [];
        const newSeries = [];
        for (var j = 0; j < this.state.bandwidthData.length; j++) {
            data[j] =
                this.state.bandwidthData[j]["Acct-Total-Octets"] /
                    (1000 * 1000) +
                "mb";
            option_categories.push(
                new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                }).format(
                    new Date(this.state.bandwidthData[j]["ReportingTimestamp"])
                )
            );
        }
        newSeries.push({
            data: data,
            name: "Bandwidth (mb)",
        });
        this.setState({
            series: newSeries[0],
            loading: false,

            options1: {
                chart: {
                    id: "line",
                    toolbar: {
                        show: false,
                    },
                    height: "400px",
                    width: "100%",
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "55%",
                        endingShape: "rounded",
                    },
                },
                legend: {
                    show: false,
                    showForSingleSeries: true,
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                },
                xaxis: {
                    type: "category",
                    categories: option_categories,
                    tickAmount: 6,
                    tickPlacement: "between",
                    title: {
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: "10px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 600,
                            cssClass: "apexcharts-xaxis-title",
                        },
                    },
                    labels: {
                        show: true,
                        rotate: 0,
                        offsetX: 0,
                        hideOverlappingLabels: true,
                        formatter: function (value, timestamp) {
                            return new Intl.DateTimeFormat("en-US", {
                                month: "numeric",
                                day: "numeric",
                            }).format(new Date(value)); // The formatter function overrides format property
                        },
                        style: {
                            fontSize: "12px",
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    decimalsInFloat: 0,
                    title: {
                        text: "MegaBytes Transferred",
                    },
                },
                tooltip: {
                    x: {
                        show: true,
                        formatter: function (value, timestamp) {
                            return new Intl.DateTimeFormat("en-US", {
                                month: "numeric",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                            }).format(new Date(value)); // The formatter function overrides format property
                        },
                    },
                },
                responsive: [
                    // {
                    //   breakpoint: 992,
                    //   options: {
                    //     xaxis: {
                    //       type: "category",
                    //       categories: option_categories,
                    //       tickAmount: 6,
                    //       tickPlacement: "between",
                    //       title: {
                    //         offsetX: 0,
                    //         offsetY: 0,
                    //         style: {
                    //           color: undefined,
                    //           fontSize: "10px",
                    //           fontFamily: "Helvetica, Arial, sans-serif",
                    //           fontWeight: 600,
                    //           cssClass: "apexcharts-xaxis-title",
                    //         },
                    //       },
                    //       labels: {
                    //         show: true,
                    //         rotate: 0,
                    //         offsetX: 5,
                    //         hideOverlappingLabels: true,
                    //         formatter: function (value, timestamp) {
                    //           return new Intl.DateTimeFormat("en-US", {
                    //             month: "numeric",
                    //             day: "numeric",
                    //           }).format(new Date(value)); // The formatter function overrides format property
                    //         },
                    //         style: {
                    //           fontSize: "10px",
                    //         },
                    //       },
                    //       tooltip: {
                    //         enabled: false,
                    //       },
                    //     },
                    //   },
                    // },
                    // {
                    //   breakpoint: 450,
                    //   options: {
                    //     xaxis: {
                    //       type: "category",
                    //       categories: option_categories,
                    //       tickAmount: 6,
                    //       tickPlacement: "between",
                    //       title: {
                    //         offsetX: 0,
                    //         offsetY: 0,
                    //         style: {
                    //           color: undefined,
                    //           fontSize: "10px",
                    //           fontFamily: "Helvetica, Arial, sans-serif",
                    //           fontWeight: 600,
                    //           cssClass: "apexcharts-xaxis-title",
                    //         },
                    //       },
                    //       labels: {
                    //         show: true,
                    //         rotate: 0,
                    //         offsetX: 5,
                    //         hideOverlappingLabels: true,
                    //         formatter: function (value, timestamp) {
                    //           return new Intl.DateTimeFormat("en-US", {
                    //             month: "numeric",
                    //             day: "numeric",
                    //           }).format(new Date(value)); // The formatter function overrides format property
                    //         },
                    //         style: {
                    //           fontSize: "10px",
                    //         },
                    //       },
                    //       tooltip: {
                    //         enabled: false,
                    //       },
                    //     },
                    //   },
                    // },
                ],
            },
        });
        this.setState({ loading: false });
    };
    render() {
        return (
            <>
                {this.state.loading === true ? (
                    <div id="overlay" style={{ display: "block" }}>
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : this.state.bandwidthData.length !== 0 &&
                  this.state.bandwidthData !== [] ? (
                    <Chart
                        options={
                            this.state.options1 !== undefined
                                ? this.state.options1
                                : this.state.options
                        }
                        series={[this.state.series]}
                        type="bar"
                        height="195"
                    />
                ) : (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "210px",
                        }}
                    >
                        <h4 style={{ color: "#BBB" }}>No Bandwidth Data</h4>
                    </div>
                )}
            </>
        );
    }
}
export default App;
