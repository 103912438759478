/* Create Account screen */

import React, { Component } from "react";

import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBModal,
    MDBModalHeader,
    MDBModalFooter,
    MDBInput,
    MDBModalBody,
} from "mdbreact";

import axios from "../../../axios";

import brandsData from "../../../brands/common/brands.json";

class SelectPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planList: [],
            loading: false,
            other: false,
            reason: [],
            radio: [],
            cancelledDate: undefined,
        };
    }

    cancelService = () => {
        this.setState({
            modalCancel: true,
            loading: true,
            cancelledDate: undefined,
        });
        let reason = [];
        this.state.radio.length === 0
            ? (reason = this.state.reason)
            : reason.push(this.state.radio);
        reason = reason.filter((item) => item !== "other");
        if (
            this.state.reason.includes("other") ||
            this.state.radio.includes("other")
        ) {
            reason.push("Other: " + this.state.otherSpecify);
        }
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            StripeClientSecret: sessionStorage.getItem(
                "stripeClientSecretSubscriber"
            ),
            CancellationReasons: reason,
        };
        if (reason.length === 0) {
            this.setState({
                cancelErrorMessage: "Please select a reason for cancellation",
            });
            this.setState({
                loading: false,
            });
        } else {
            this.setState({ cancelErrorMessage: "" });
            axios
                .post("/subscriberconsole/cancelSubscription", body, {
                    headers: {},
                })
                .then((response) => {
                    this.setState({
                        cancelledDate: response.data["EndDateTimeUTC.ISO8601"],
                    });
                    this.setState({
                        loading: false,
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                    });
                    this.setState({ error: true });
                });
        }
    };

    handleSubmit = () => {
        window.location.href = "/myaccount";
        sessionStorage.removeItem("stripeClientSecret");
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: !this.state[event.target.name] });
        if (!this.state[event.target.name] === true) {
            this.setState((prevState) => ({
                reason: [...prevState.reason, event.target.name],
            }));
        }
    };

    changeHandlerRadio = (nr) => () => {
        this.setState({
            radio: nr,
        });
    };

    changeHandlerOtherSpecify = (event) => {
        this.setState({ otherSpecify: event.target.value });
    };

    render() {
        let cancelInputType;
        let phoneNumber;
        brandsData.forEach(({ name, url, cancel_input_type, phone_number }) => {
            if (url.includes(window.location.hostname)) {
                cancelInputType = cancel_input_type;
                phoneNumber = phone_number;
            }
        });
        return (
            <>
                <div className="SelectPlan">
                    {this.state.cancelledDate !== undefined ? (
                        <MDBModal
                            isOpen={this.state.modalCancel}
                            size="lg"
                            className="form"
                            centered
                            overflowScroll
                        >
                            {!this.state.loading ? (
                                <>
                                    <MDBModalHeader toggle={this.handleSubmit}>
                                        We appreciate your business.
                                    </MDBModalHeader>
                                    <MDBModalBody
                                        className="successModal"
                                        id="faq"
                                    >
                                        <>
                                            <p>
                                                Your account is cancelled as of{" "}
                                                {new Intl.DateTimeFormat(
                                                    "en-US",
                                                    {
                                                        year: "numeric",
                                                        month: "numeric",
                                                        day: "numeric",
                                                    }
                                                ).format(
                                                    new Date(
                                                        this.state.cancelledDate
                                                    )
                                                )}
                                                .
                                            </p>
                                        </>
                                    </MDBModalBody>
                                    <MDBModalFooter>
                                        <div className="nextButton">
                                            <button
                                                className="btn Ripple-parent btn-default"
                                                onClick={this.handleSubmit}
                                            >
                                                Ok
                                            </button>
                                        </div>
                                    </MDBModalFooter>
                                </>
                            ) : (
                                <>
                                    <div
                                        id="overlay"
                                        style={{ display: "block" }}
                                    >
                                        <div className="overlayMessage">
                                            <div
                                                className="spinner-border spinner-border"
                                                role="status"
                                            >
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </MDBModal>
                    ) : (
                        ""
                    )}
                    <MDBContainer className="Form" fluid>
                        <MDBRow className="g-0 m-0">
                            <MDBCol
                                sm="12"
                                md="12"
                                lg="12"
                                className="ml-auto p-0"
                            >
                                <div className="topBanner">
                                    <h2>Cancel Service</h2>
                                </div>
                                <div className="FormContainer">
                                    <p className="title">
                                        We're sorry to see you go!
                                    </p>
                                    <p>
                                        We'd love the opportunity to keep your
                                        business. Please contact our support
                                        team at {phoneNumber} if you are having
                                        issues with your service. If you'd like
                                        to proceed with cancelling service,
                                        please share the reason why and click
                                        Cancel Service to proceed.
                                    </p>
                                    <div className="loginDisclosure">
                                        {cancelInputType === "checkbox" ? (
                                            <>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="Moving"
                                                            type="checkbox"
                                                            id="moving"
                                                            name="Moving"
                                                            onChange={
                                                                this
                                                                    .changeHandler
                                                            }
                                                            checked={
                                                                this.state
                                                                    .moving
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="Price"
                                                            type="checkbox"
                                                            id="price"
                                                            name="Price"
                                                            onChange={
                                                                this
                                                                    .changeHandler
                                                            }
                                                            checked={
                                                                this.state.price
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="Poor customer service"
                                                            type="checkbox"
                                                            id="poorCustomerService"
                                                            name="Poor Customer Service"
                                                            onChange={
                                                                this
                                                                    .changeHandler
                                                            }
                                                            checked={
                                                                this.state
                                                                    .poorCustomerService
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="Received a competitor promo"
                                                            type="checkbox"
                                                            id="receivedPromo"
                                                            name="Received Promo"
                                                            onChange={
                                                                this
                                                                    .changeHandler
                                                            }
                                                            checked={
                                                                this.state
                                                                    .receivedPromo
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="My promo expired"
                                                            type="checkbox"
                                                            id="promoExpired"
                                                            name="Promo Expired"
                                                            onChange={
                                                                this
                                                                    .changeHandler
                                                            }
                                                            checked={
                                                                this.state
                                                                    .promoExpired
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="Reliability"
                                                            type="checkbox"
                                                            id="reliability"
                                                            name="Reliability"
                                                            onChange={
                                                                this
                                                                    .changeHandler
                                                            }
                                                            checked={
                                                                this.state
                                                                    .reliability
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="Speeds"
                                                            type="checkbox"
                                                            id="speeds"
                                                            name="Speeds"
                                                            onChange={
                                                                this
                                                                    .changeHandler
                                                            }
                                                            checked={
                                                                this.state
                                                                    .speeds
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="Other"
                                                            type="checkbox"
                                                            id="other"
                                                            name="other"
                                                            onChange={
                                                                this
                                                                    .changeHandler
                                                            }
                                                            checked={
                                                                this.state.other
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <input
                                                            disabled={
                                                                this.state
                                                                    .other ===
                                                                false
                                                                    ? true
                                                                    : false
                                                            }
                                                            required
                                                            name="otherSpecify"
                                                            id="otherSpecify"
                                                            className="form-control"
                                                            onChange={
                                                                this
                                                                    .changeHandlerOtherSpecify
                                                            }
                                                            value={
                                                                this.state
                                                                    .otherSpecify
                                                            }
                                                            placeholder="Please specify"
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                            </>
                                        ) : (
                                            <>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="Moving"
                                                            type="radio"
                                                            id="moving"
                                                            name="Moving"
                                                            onChange={this.changeHandlerRadio(
                                                                "Moving"
                                                            )}
                                                            checked={
                                                                this.state
                                                                    .radio ===
                                                                "Moving"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="Price"
                                                            type="radio"
                                                            id="price"
                                                            name="Price"
                                                            onChange={this.changeHandlerRadio(
                                                                "Price"
                                                            )}
                                                            checked={
                                                                this.state
                                                                    .radio ===
                                                                "Price"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="Poor customer service"
                                                            type="radio"
                                                            id="poorCustomerService"
                                                            name="Poor Customer Service"
                                                            onChange={this.changeHandlerRadio(
                                                                "Poor Customer Service"
                                                            )}
                                                            checked={
                                                                this.state
                                                                    .radio ===
                                                                "Poor Customer Service"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="Received a competitor promo"
                                                            type="radio"
                                                            id="receivedPromo"
                                                            name="Received Promo"
                                                            onChange={this.changeHandlerRadio(
                                                                "Received Promo"
                                                            )}
                                                            checked={
                                                                this.state
                                                                    .radio ===
                                                                "Received Promo"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="My promo expired"
                                                            type="radio"
                                                            id="promoExpired"
                                                            name="Promo Expired"
                                                            onChange={this.changeHandlerRadio(
                                                                "Promo Expired"
                                                            )}
                                                            checked={
                                                                this.state
                                                                    .radio ===
                                                                "Promo Expired"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="Reliability"
                                                            type="radio"
                                                            id="reliability"
                                                            name="Reliability"
                                                            onChange={this.changeHandlerRadio(
                                                                "Reliability"
                                                            )}
                                                            checked={
                                                                this.state
                                                                    .radio ===
                                                                "Reliability"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol
                                                        col="12"
                                                        className="leftAlign agree"
                                                    >
                                                        <MDBInput
                                                            label="Speeds"
                                                            type="radio"
                                                            id="speeds"
                                                            name="Speeds"
                                                            onChange={this.changeHandlerRadio(
                                                                "Speeds"
                                                            )}
                                                            checked={
                                                                this.state
                                                                    .radio ===
                                                                "Speeds"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                            </>
                                        )}
                                        <div className="error-message">
                                            {this.state.cancelErrorMessage}
                                        </div>
                                    </div>
                                    <div className="backButton">
                                        <a
                                            className="btn Ripple-parent btn-default"
                                            href="/myaccount"
                                        >
                                            Back
                                        </a>
                                    </div>
                                    <div className="nextButton">
                                        <button
                                            className="btn Ripple-parent btn-default"
                                            onClick={this.cancelService}
                                        >
                                            {this.state.loading === true ? (
                                                <div
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                >
                                                    <span className="sr-only">
                                                        Loading...
                                                    </span>
                                                </div>
                                            ) : (
                                                "Cancel Service"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol></MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>
            </>
        );
    }
}

export default SelectPlan;
