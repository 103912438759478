import React, { Component } from "react";

import {
    MDBBtn,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBSpinner,
} from "mdbreact";

import ViewTransactionsData from "../../../components/SubscriberMgmt/Transactions/Datatables/ViewTransactionsData";

import axios from "../../../axios";

import { withTranslation } from "react-i18next";

class ManageDevices extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.getTransactions();
    }

    toggleTransactions = () => {
        this.setState({
            modalTransactionsDetails: !this.state.modalTransactionsDetails,
        });
    };

    getTransactions = () => {
        this.setState({
            modalTransactionsDetails: !this.state.modalTransactionsDetails,
        });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
        };
        axios
            .post("/subscriberconsole/listPayments", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    transactions: response.data.Payments,
                });
            })
            .catch((error) => {
                this.setState({ error: true });
            });
    };

    render() {
        return (
            <>
                <MDBModalHeader toggle={this.props.toggleViewTransactions}>
                    <h4>Transactions</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    {this.state.transactions !== undefined ? (
                        <>
                            <ViewTransactionsData
                                transactions={this.state.transactions}
                                toggleTransactions={
                                    this.props.toggleTransactions
                                }
                            />
                        </>
                    ) : (
                        <MDBSpinner small />
                    )}
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn
                        color="danger"
                        onClick={this.props.toggleViewTransactions}
                    >
                        Ok
                    </MDBBtn>
                </MDBModalFooter>
            </>
        );
    }
}

export default withTranslation()(ManageDevices);
