import React from "react";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <GridToolbarQuickFilter />
        </Box>
    );
}
export default function Basic(props) {
    let userAttributes = [];
    let i = 0;
    let columns = [];
    let rows = [];
    if (
        props.venueUnitPolicyInfo !== undefined
        // &&
        // props.venueListUtilization !== undefined
    ) {
        for (const key of Object.keys(props.venueUnitPolicyInfo)) {
            //  if (
            //   props.venueListUtilization[props.venueList[key]["VenueID"]] !==
            //   undefined
            // ) {
            if (props.venueUnitPolicyInfo !== undefined) {
                userAttributes.push({
                    id: key,
                    profileName: props.profileName,
                    networkName: props.networkName,
                    venueName: props.venueUnitPolicyInfo[key]["VenueName"],
                    unitName: props.venueUnitPolicyInfo[key]["UnitID"],
                    buildingName:
                        props.venueUnitPolicyInfo[key]["UnitBuilding"],
                    floorName: props.venueUnitPolicyInfo[key]["UnitFloor"],
                    psk: props.psk,
                });
                i = i + 1;
            }
        }
    }
    columns = [
        {
            headerName: "Venue Name",
            field: "venueName",
            flex: 0.2,
            minWidth: 200,
        },
        {
            headerName: "Unit",
            field: "unitName",
            // minWidth: 120,
            flex: 0.2,
        },
        {
            headerName: "Building",
            field: "buildingName",
            // minWidth: 120,
            flex: 0.2,
        },
        {
            headerName: "Floor",
            field: "floorName",
            // minWidth: 120,
            flex: 0.2,
        },
    ];
    rows = userAttributes;
    return (
        <div style={{ height: 250, width: "100%" }}>
            <DataGrid
                rows={rows}
                columns={columns}
                hideFooterPagination={true}
                hideFooter={true}
                getRowHeight={({ id, densityFactor }) => {
                    return 60 * densityFactor;
                }}
                slots={{ toolbar: QuickSearchToolbar }}
                disableRowSelectionOnClick={true}
            />
        </div>
    );
}
