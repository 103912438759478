import React, { Component } from "react";

import { MDBModal, MDBModalBody, MDBModalHeader, MDBSpinner } from "mdbreact";

import ManageProfilesData from "../../../components/SubscriberMgmt/ManageProfiles/Datatables/ManageProfilesDataMUI";

import KeyManage from "../../../components/SubscriberMgmt/ManageProfiles/KeyManageProfiles";

import axios from "../../../axios";

import _ from "lodash";

import { withTranslation } from "react-i18next";

const initialState = { isLoading: false, results: [], value: "" };

let getResults;

let source1 = [];

class ManageProfiles extends Component {
    state = initialState;

    constructor(props) {
        super(props);

        this.state = {
            selectedKeyID: undefined,
            keyNameListArray: this.props.keyNameListArray,
            selectedName: null,
            keys: this.props.keyInfo,
            sortedKeys: this.props.sortedKeys,
            keyNameListArrayAll: undefined,
            selectedNetworkIndex: 0,
            devicesForKey: null,
            selectedKeyIDForBandwidth: undefined,
            psk: undefined,
            token: this.props.token,
        };
    }
    componentDidMount() {
        this.getSearchResults();
    }

    getSearchResults = () => {
        let key = [];
        let iteration = 0;
        key = Object.keys(this.props.keyNameListArray);
        key.forEach((val, index) => {
            getResults = () =>
                _.times(1, () => ({
                    title: this.props.SessionList[0][val],
                    network: this.props.ssidName,
                    description: this.props.ssidName,
                    keyID: val,
                    keyName: this.props.SessionList[0][val],
                }));

            const source = _.range(0, 3).reduce((memo1) => {
                const name = val;

                // eslint-disable-next-line no-param-reassign
                memo1 = {
                    name,
                    results: getResults(),
                };

                return memo1;
            }, {});
            source1.push(source);
            iteration = iteration + 1;
        });
    };
    handleResultSelect = (e, { result }) =>
        this.setState({
            value: result.title,
            modalManageKey: !this.state.modalManageKey,
            selectedKeyID: result.keyID,
            selectedKeyName: result.keyName,
            psk: result.psk,
            fromDataTable: true,
        });

    handleSearchChange = (e, { value }) => {
        this.setState({ isLoading: true, value });
        let re = "";
        setTimeout(() => {
            this.setState({ results: "" });
            if (this.state.value.length < 1) return this.setState(initialState);

            re = new RegExp(_.escapeRegExp(this.state.value), "i");

            const isMatch = (result) => re.test(result.title + result.network);
            this.setState({
                filteredResults: _.reduce(
                    source1,
                    (memo2, data, name) => {
                        let results = _.filter(data.results, isMatch);

                        if (results.length || results[0] !== undefined)
                            memo2[name] = { name, results }; // eslint-disable-line no-param-reassign

                        return memo2;
                    },
                    {}
                ),
            });
            this.setState({
                isLoading: false,
                results: this.state.filteredResults,
            });
        }, 300);
    };
    toggleManageKey = (
        fromDataTable,
        keyID,
        keyName,
        psk,
        network,
        selectedNetworkIndex,
        iswpa2,
        devices,
        venueUnitPolicies
    ) => {
        // console.log(keyID)
        this.setState({
            modalManageKey: !this.state.modalManageKey,
            selectedKeyID: keyID,
            selectedKeyIDForBandwidth: keyID,
            selectedKeyName: keyName,
            psk: psk,
            fromDataTable: fromDataTable,
            selectedName: network,
            selectedNetworkIndex: selectedNetworkIndex,
            isWPA2: iswpa2,
            devicesForKey: devices,
            venueUnitPolicies: venueUnitPolicies,
        });
    };
    toggleManageKeyModal = () => {
        this.setState({
            modalManageKey: !this.state.modalManageKey,
        });
        this.listKeys();
    };

    updateNameKeyProfile = (value, index, keyInfo, newpassword, tag) => {
        this.listKeys();
        this.setState({ selectedKeyName: value });
        this.setState({ selectedKeyID: index });
        this.setState({ keyInfo: keyInfo });

        this.props.updateNameProfile(value, index, keyInfo, newpassword, tag);
    };

    updateName = (value, index) => {
        this.setState({ selectedName: value });
        this.setState({ selectedID: index });
    };

    updateNameKey = (value, index, selectedNetworkIndex) => {
        // console.log(value);
        // console.log(index);
        // console.log(selectedNetworkIndex);
        this.listKeys();
        this.setState({ selectedKeyName: value });
        this.setState({ selectedKeyID: index });
        this.setState({ selectedKeyIDForBandwidth: index });
        this.setState({
            psk: this.state.sortedKeys[
                selectedNetworkIndex !== undefined
                    ? selectedNetworkIndex
                    : this.state.selectedNetworkIndex
            ][index]["psk"],
        });
        this.setState({
            addedOn:
                this.state.sortedKeys[
                    selectedNetworkIndex !== undefined
                        ? selectedNetworkIndex
                        : this.state.selectedNetworkIndex
                ][index]["dateAddedUTC.ISO8601"],
        });
        this.setState({
            recent: this.state.sortedKeys[
                selectedNetworkIndex !== undefined
                    ? selectedNetworkIndex
                    : this.state.selectedNetworkIndex
            ][index]["mostRecentUseUTC.ISO8601"],
        });
        this.setState({
            isWPA2: this.state.sortedKeys[
                selectedNetworkIndex !== undefined
                    ? selectedNetworkIndex
                    : this.state.selectedNetworkIndex
            ][index]["wpa2enterprise_auth"],
        });
        this.setState({
            devicesForKey:
                this.state.sortedKeys[
                    selectedNetworkIndex !== undefined
                        ? selectedNetworkIndex
                        : this.state.selectedNetworkIndex
                ][index]["Devices"],
            venueUnitPolicies:
                this.state.sortedKeys[
                    selectedNetworkIndex !== undefined
                        ? selectedNetworkIndex
                        : this.state.selectedNetworkIndex
                ][index]["VenueUnitPolicies"],
        });
    };

    updateNameNetwork = (value, index) => {
        let keyNameListArray = Object.entries(
            this.state.keyNameListArray[0][index]
        );
        // console.log(keyNameListArray)
        this.setState({ selectedName: value });
        this.setState({
            selectedNetworkIndex: index,
            // keyNameListArray: keyNameListArray
        });
        this.updateNameKey(
            keyNameListArray[0][1],
            keyNameListArray[0][0],
            index
        );
    };

    listKeys = () => {
        this.setState({ loading: true });

        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
        };

        let bodySessions = [];
        let iterationSessions = 0;
        let updatedSessions = [];
        let keys1;
        let keys2 = [];
        let updatedKeys = {};

        axios
            .post("/subscriberconsole/listKeys", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    devices: [],
                });
                this.setState({ keys: [] });
                keys1 = response.data.KeysInKeyPools;

                if (
                    Object.values(keys1).findIndex(
                        (item) => item.ssid.toLowerCase() === "login to view"
                    ) === -1
                ) {
                    _.merge(updatedKeys, keys1);
                    for (let i = 0; i < Object.keys(keys1).length; i++) {
                        if (
                            keys1[Object.keys(keys1)[i]].ssid.toLowerCase() ===
                            "login to view"
                        ) {
                            // updatedKeys[Object.keys(keys1)[i]] = keys1[Object.keys(keys1)[i]]
                            // console.log(updatedKeys)
                            // console.log(Object.keys(keys1)[i])
                            keys2 = keys1[Object.keys(keys1)[i]];
                        }
                    }
                } else {
                    for (let i = 0; i < Object.keys(keys1).length; i++) {
                        if (
                            keys1[Object.keys(keys1)[i]].ssid.toLowerCase() ===
                            "login to view"
                        ) {
                            updatedKeys[Object.keys(keys1)[i]] =
                                keys1[Object.keys(keys1)[i]];
                            // console.log(updatedKeys)
                            // console.log(Object.keys(keys1)[i])
                            keys2 = keys1[Object.keys(keys1)[i]];
                        }
                    }
                    for (let k = 0; k < Object.keys(keys2).length; k++) {
                        if (
                            Object.keys(keys2)[k] !== "DisplayOrder" &&
                            Object.keys(keys2)[k] !== "ssid"
                        ) {
                            // console.log(keys2[Object.keys(keys2)[k]]['psk'])
                            let keytagname = Object.keys(keys2)[k];
                            const obj = keys2;
                            Object.defineProperty(
                                obj,
                                keytagname,
                                Object.getOwnPropertyDescriptor(obj, keytagname)
                            );
                            // delete keys2[keytagname];
                        }
                        // console.log(keys2)
                        const ssid = "login to view";
                        keys2 = { ...keys2, ssid };
                    }
                    updatedKeys["Ubiquiti5g"] = keys2;
                }
                for (let m = 0; m < Object.keys(updatedKeys).length; m++) {
                    let objectNumber = Object.keys(updatedKeys)[m];
                    let unitIDforSSID;
                    let suffixForSSID;
                    if (
                        updatedKeys[objectNumber]["ssid"].toLowerCase() ===
                        "login to view"
                    )
                        for (
                            let n = 0;
                            n < Object.keys(updatedKeys[objectNumber]).length;
                            n++
                        ) {
                            let indexNumber = Object.keys(
                                updatedKeys[objectNumber]
                            )[n];
                            if (
                                indexNumber !== "ssid" &&
                                indexNumber !== "DisplayOrder"
                            ) {
                                let venueUnitPolicyIDforSSID = Object.keys(
                                    updatedKeys[objectNumber][indexNumber][
                                        "VenueUnitPolicies"
                                    ]
                                );
                                // console.log(venueUnitPolicyIDforSSID)
                                // console.log(updatedKeys[Object.keys(updatedKeys)[m]][indexNumber]['VenueUnitPolicies'][venueUnitPolicyIDforSSID])
                                if (
                                    updatedKeys[Object.keys(updatedKeys)[m]][
                                        indexNumber
                                    ]["VenueUnitPolicies"][
                                        venueUnitPolicyIDforSSID
                                    ] !== undefined
                                )
                                    unitIDforSSID =
                                        updatedKeys[
                                            Object.keys(updatedKeys)[m]
                                        ][indexNumber]["VenueUnitPolicies"][
                                            venueUnitPolicyIDforSSID
                                        ]["UnitID"];
                                suffixForSSID =
                                    updatedKeys[Object.keys(updatedKeys)[m]][
                                        indexNumber
                                    ]["VenueUnitPolicies"][
                                        venueUnitPolicyIDforSSID
                                    ]["VenueAddressLine2"];
                            }
                            // console.log(unitIDforSSID)
                            if (
                                unitIDforSSID !== undefined &&
                                updatedKeys[objectNumber][
                                    "ssid"
                                ].toLowerCase() === "login to view"
                            ) {
                                if (objectNumber === "Ubiquiti5g") {
                                    const ssid =
                                        unitIDforSSID + " " + suffixForSSID;
                                    const DisplayOrder = "10";
                                    updatedKeys[objectNumber] = {
                                        ...updatedKeys[objectNumber],
                                        ssid,
                                        DisplayOrder,
                                    };
                                } else {
                                    const ssid = unitIDforSSID;
                                    const DisplayOrder = "50";
                                    updatedKeys[objectNumber] = {
                                        ...updatedKeys[objectNumber],
                                        ssid,
                                        DisplayOrder,
                                    };
                                }
                            }
                        }
                }
                var sortedKeys = _(updatedKeys)
                    .map(function (v, k) {
                        // insert the key into the object
                        return _.merge({}, v, { key: k });
                    })
                    .sortBy("DisplayOrder") // sort by name
                    .value();

                this.setState({ venueList: Object.keys(updatedKeys) });
                this.setState({ keys: [updatedKeys] });
                this.setState({ sortedKeys: sortedKeys });
                this.setState({ venueName: this.state.venueList });
                this.setState({
                    devices: response.data.AllDevices,
                    deviceCountOffline: response.data.DeviceCountOffline,
                    deviceCountOnline: response.data.DeviceCountOnline,
                    messages: response.data.messages_from_venues,
                    allData: response.data,
                    token: response.data.SubscriberToken,
                });
                sessionStorage.setItem(
                    "emailSubscriber",
                    response.data.EmailAddress
                );
                sessionStorage.setItem(
                    "phoneNumberSubscriber",
                    response.data["PhoneNumber.E164"]
                );
                sessionStorage.setItem("firstName", response.data.GivenName);
                sessionStorage.setItem("lastName", response.data.FamilyName);
                let keyNameArray = [];
                let keyNameListArray = [];
                let keyNameListArrayAll = [];
                var obj = {};
                var obj_all = {};
                for (let i = 0; i < this.state.sortedKeys.length; i++) {
                    keyNameArray.push(this.state.sortedKeys[i]);
                    obj[i] = {};
                    let keyNameList = Object.keys(this.state.sortedKeys[i]);
                    // console.log(keyNameList)
                    for (
                        let j = 0;
                        j < Object.keys(this.state.sortedKeys[i]).length;
                        j++
                    ) {
                        let keyPoolID = keyNameList[j];
                        if (
                            this.state.sortedKeys[i][keyPoolID]["KeyTag"] !==
                            undefined
                        ) {
                            // console.log(this.state.sortedKeys[i][keyPoolID])
                            obj[i][keyPoolID] = 0;
                            obj[i][keyPoolID] =
                                this.state.sortedKeys[i][keyPoolID]["KeyTag"];
                            obj_all[i] = this.state.sortedKeys[i][keyPoolID];
                            obj_all[i].keyTag = keyPoolID;
                            obj_all[i].network =
                                this.state.sortedKeys[i]["ssid"];

                            obj_all[i].selectedNetworkIndex = i;

                            let venueUnitPolicyID = Object.keys(
                                this.state.sortedKeys[i][keyPoolID][
                                    "VenueUnitPolicies"
                                ]
                            );
                            obj_all[i].venueID =
                                this.state.sortedKeys[i][keyPoolID][
                                    "VenueUnitPolicies"
                                ][venueUnitPolicyID[0]]["VenueID"];
                            obj_all[i].servicePlanID =
                                this.state.sortedKeys[i][keyPoolID][
                                    "VenueUnitPolicies"
                                ][venueUnitPolicyID[0]]["ServicePlan"][
                                    "ServicePlanID"
                                ];
                            // this.setState({
                            // 	paymentFailed:
                            // 		this.state.sortedKeys[i][keyPoolID][
                            // 			"VenueUnitPolicies"
                            // 		][venueUnitPolicyID[0]]["paymentFailed"],
                            // });
                            if (
                                !this.state.sortedKeys[i][keyPoolID][
                                    "wpa2enterprise_auth"
                                ]
                            ) {
                                this.setState({
                                    paymentFailed:
                                        this.state.sortedKeys[i][keyPoolID][
                                            "VenueUnitPolicies"
                                        ][venueUnitPolicyID[0]][
                                            "paymentFailed"
                                        ],
                                });
                                sessionStorage.setItem(
                                    "venueIDSubscriber",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["VenueID"]
                                );
                                sessionStorage.setItem(
                                    "stripeClientSecretSubscriber",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]][
                                        "StripeClientSecret"
                                    ]
                                );
                                sessionStorage.setItem(
                                    "unitID_internalSubscriber",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["UnitID_internal"]
                                );
                                sessionStorage.setItem(
                                    "servicePlanIDSubscriber",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["ServicePlan"][
                                        "ServicePlanID"
                                    ]
                                );
                                sessionStorage.setItem(
                                    "servicePlanNameSubscriber",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["ServicePlan"][
                                        "ServicePlanName"
                                    ]
                                );
                                sessionStorage.setItem(
                                    "servicePlanNameSubscriber",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["ServicePlan"][
                                        "ServicePlanName"
                                    ]
                                );
                                sessionStorage.setItem(
                                    "address",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["AddressLine1"]
                                );
                                sessionStorage.setItem(
                                    "city",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["City"]
                                );
                                sessionStorage.setItem(
                                    "state",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["State"]
                                );
                                sessionStorage.setItem(
                                    "postalcode",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["PostalCode"]
                                );
                                sessionStorage.setItem(
                                    "unit",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["UnitID_internal"]
                                );
                                sessionStorage.setItem(
                                    "unitName",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["UnitID"]
                                );
                                sessionStorage.setItem(
                                    "dateStart",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]][
                                        "StartDateTimeUTC.ISO8601"
                                    ]
                                );
                                sessionStorage.setItem(
                                    "logoURL",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["DisplayOptions"][
                                        "LogoPNG.URL"
                                    ]
                                );
                                sessionStorage.setItem(
                                    "supportEmail",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["DisplayOptions"][
                                        "VenueSupportEmailAddress"
                                    ]
                                );
                                sessionStorage.setItem(
                                    "supportPhone",
                                    this.state.sortedKeys[i][keyPoolID][
                                        "VenueUnitPolicies"
                                    ][venueUnitPolicyID[0]]["DisplayOptions"][
                                        "VenueSupportPhoneNumber.E164"
                                    ]
                                );
                            }
                        }
                    }
                }
                // console.log(obj);
                keyNameListArrayAll.push(obj_all);
                keyNameListArray.push(obj);
                // console.log("keyNameListArray: ", keyNameListArray)
                this.setState({ keyNameListArray: keyNameListArray });
                this.setState({ keyNameListArrayAll: keyNameListArrayAll });
                this.setState({ keyNameArray: keyNameArray });
                const sessionList = sortedKeys;
                let SessionListID = null;

                bodySessions.push(sessionList);
                bodySessions.forEach((val) => {
                    SessionListID = Object.keys(bodySessions[0]);
                });
                if (this.state.SessionList !== []) {
                    this.setState({ SessionList: [] });
                }
                SessionListID.forEach((val, index) => {
                    updatedSessions = bodySessions.map((sessionList) => {
                        return {
                            ...sessionList[SessionListID[iterationSessions]],
                        };
                    });
                    if (updatedSessions[0] !== undefined) {
                        this.setState({
                            SessionList: [
                                ...this.state.SessionList,
                                updatedSessions,
                            ],
                        });
                        iterationSessions = iterationSessions + 1;
                    }
                });

                if (this.state.keys !== undefined) {
                    this.setState({ loading: false });
                }
                if (this.state.keyNameArray.length !== 0) {
                    this.getServicePlans();
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.setState({ showError: true });
                this.setState({ error: error });
            });
    };

    render() {
        const { t } = this.props;
        let keyNameListArray = Object.entries(
            this.state.keyNameListArray[0][this.state.selectedNetworkIndex]
        );
        this.state.keyNameListArrayAll !== undefined
            ? Object.entries(this.state.keyNameListArrayAll[0][0])
            : Object.entries(this.props.keyNameListArrayAll[0][0]);
        // console.log("props from ManageProfiles: ", this.props)
        return (
            <>
                <MDBModal
                    isOpen={this.state.modalManageKey}
                    toggle={this.toggle}
                    size="lg"
                    className="form editProfile"
                >
                    <KeyManage
                        venueName={this.props.venueName}
                        toggleManageKey={this.toggleManageKeyModal}
                        ssidName={this.state.selectedName}
                        keyInfo={this.state.keys}
                        sortedKeys={this.state.sortedKeys}
                        keyID={this.state.selectedKeyID}
                        submitHandler={this.submitHandler}
                        downloadQR={this.downloadQR}
                        copyPassword={this.copyPassword}
                        keyNameListArray={keyNameListArray}
                        keyNameListArrayAll={this.props.keyNameListArrayAll}
                        updateNameKeyProfile={this.updateNameKeyProfile}
                        updateNameKey={this.updateNameKey}
                        updateNameNetwork={this.updateNameNetwork}
                        selectedKeyName={this.state.selectedKeyName}
                        password={this.state.psk}
                        listKeys={this.listKeys}
                        firstPSK={this.state.firstPSK}
                        fromDataTable={this.state.fromDataTable}
                        selectedNetworkIndex={this.state.selectedNetworkIndex}
                        SessionList={this.props.SessionList}
                        devices={
                            this.state.devicesForKey !== null
                                ? this.state.devicesForKey
                                : this.props.devices
                        }
                        selectedName={this.state.selectedName}
                        iswpa2={this.state.isWPA2}
                        updateNameProfile={this.props.updateNameProfile}
                        keyIDForBandwith={this.state.selectedKeyIDForBandwidth}
                        token={this.state.token}
                        venueUnitPolicyInfo={this.state.venueUnitPolicies}
                    />
                </MDBModal>
                <MDBModalHeader toggle={this.props.toggleManageProfiles}>
                    <h4>{t("manageprofilestable.title")}</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    {this.state.keys.length !== 0 ? (
                        <>
                            <ManageProfilesData
                                keyNameListArrayAll={
                                    this.state.keyNameListArrayAll !== undefined
                                        ? Object.entries(
                                              this.state.keyNameListArrayAll[0]
                                          )
                                        : Object.entries(
                                              this.props.keyNameListArrayAll[0]
                                          )
                                }
                                keys={this.state.keys}
                                toggleManageKey={this.toggleManageKey}
                            />
                        </>
                    ) : (
                        <MDBSpinner small />
                    )}
                </MDBModalBody>
            </>
        );
    }
}

export default withTranslation()(ManageProfiles);
