import React, { Component } from "react";
import Chart from "react-apexcharts";
import axios from "../../../../axios";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bandwidthData: undefined,
            loading: false,
            options1: undefined,
            category: [],
            name: [],
            series: [],
        };
    }
    componentDidMount() {
        this.getBandwidthData();
    }
    getBandwidthData = (keyID) => {
        let bandwidthData;
        this.setState({ loading: true, bandwidthData: undefined });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            "Calling-Station-Id": this.props.callingStationID,
        };
        axios
            .post("/subscriberconsole/listDeviceSessions", body, {
                headers: {},
            })
            .then((response) => {
                bandwidthData = response.data.UsageData;
                this.setState({
                    bandwidthData: response.data.UsageData,
                });
                if (this.state.bandwidthData !== undefined) {
                    this.setSeries(bandwidthData);
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.getBandwidthData(keyID);
                this.setState({ error: true });
                this.setState({ loading: false });
            });
    };
    setBandwidthData = () => {
        for (var i = 0; i < this.state.bandwidthData.length; i++) {
            if (
                this.state.bandwidthData[i]["Calling-Station-Id"].includes(
                    this.props.callingStationID
                )
            ) {
                this.setState({
                    name: [
                        ...this.state.name,
                        {
                            [this.props.callingStationID]: [
                                {
                                    data: this.state.bandwidthData[i][
                                        "Acct-Output-Octets"
                                    ],
                                    category:
                                        this.state.bandwidthData[i][
                                            "ReportingTimestamp"
                                        ],
                                },
                            ],
                        },
                    ],
                });
            }
        }
        if (this.state.name !== []) {
            this.setSeries();
        }
    };
    setSeries = (bandwidthData) => {
        this.setState({ loading: true });
        let option_categories = [];
        let data = [];
        this.setState({
            bandwidthData: bandwidthData,
        });
        const newSeries = [];
        for (var j = 0; j < bandwidthData.length; j++) {
            data[j] = bandwidthData[j]["Acct-Output-Octets"] / (1000 * 1000);
            option_categories.push(
                new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                }).format(new Date(bandwidthData[j]["ReportingTimestamp"]))
            );
        }
        newSeries.push({
            data: data,
            name: "Bandwidth (mb)",
        });
        this.setState({
            series: newSeries,
            loading: false,

            options1: {
                chart: {
                    id: "line",
                    toolbar: {
                        show: false,
                    },
                    height: "400px",
                    width: "100%",
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "55%",
                        endingShape: "rounded",
                    },
                },
                legend: {
                    show: false,
                    showForSingleSeries: true,
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                },
                xaxis: {
                    type: "category",
                    categories: option_categories,
                    tickAmount: 6,
                    tickPlacement: "between",
                    title: {
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: "10px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 600,
                            cssClass: "apexcharts-xaxis-title",
                        },
                    },
                    labels: {
                        show: true,
                        rotate: 0,
                        offsetX: 0,
                        hideOverlappingLabels: true,
                        formatter: function (value, timestamp) {
                            return new Intl.DateTimeFormat("en-US", {
                                month: "numeric",
                                day: "numeric",
                            }).format(new Date(value)); // The formatter function overrides format property
                        },
                        style: {
                            fontSize: "12px",
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    decimalsInFloat: 0,
                    title: {
                        text: "MegaBytes Transferred",
                    },
                },
                tooltip: {
                    x: {
                        show: true,
                        formatter: function (value, timestamp) {
                            return new Intl.DateTimeFormat("en-US", {
                                month: "numeric",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                            }).format(new Date(value)); // The formatter function overrides format property
                        },
                    },
                },
                // responsive: [
                //   {
                //     breakpoint: 992,
                //     options: {
                //       xaxis: {
                //         type: "category",
                //         categories: option_categories,
                //         tickAmount: 6,
                //         tickPlacement: "between",
                //         title: {
                //           offsetX: 0,
                //           offsetY: 0,
                //           style: {
                //             color: undefined,
                //             fontSize: "10px",
                //             fontFamily: "Helvetica, Arial, sans-serif",
                //             fontWeight: 600,
                //             cssClass: "apexcharts-xaxis-title",
                //           },
                //         },
                //         labels: {
                //           show: true,
                //           rotate: 0,
                //           offsetX: 5,
                //           hideOverlappingLabels: true,
                //           formatter: function (value, timestamp) {
                //             return new Intl.DateTimeFormat("en-US", {
                //               month: "numeric",
                //               day: "numeric",
                //             }).format(new Date(value)); // The formatter function overrides format property
                //           },
                //           style: {
                //             fontSize: "10px",
                //           },
                //         },
                //         tooltip: {
                //           enabled: false,
                //         },
                //       },
                //     },
                //   },
                //   {
                //     breakpoint: 450,
                //     options: {
                //       xaxis: {
                //         type: "category",
                //         categories: option_categories,
                //         tickAmount: 6,
                //         tickPlacement: "between",
                //         title: {
                //           offsetX: 0,
                //           offsetY: 0,
                //           style: {
                //             color: undefined,
                //             fontSize: "10px",
                //             fontFamily: "Helvetica, Arial, sans-serif",
                //             fontWeight: 600,
                //             cssClass: "apexcharts-xaxis-title",
                //           },
                //         },
                //         labels: {
                //           show: true,
                //           rotate: 0,
                //           offsetX: 5,
                //           hideOverlappingLabels: true,
                //           formatter: function (value, timestamp) {
                //             return new Intl.DateTimeFormat("en-US", {
                //               month: "numeric",
                //               day: "numeric",
                //             }).format(new Date(value)); // The formatter function overrides format property
                //           },
                //           style: {
                //             fontSize: "10px",
                //           },
                //         },
                //         tooltip: {
                //           enabled: false,
                //         },
                //       },
                //     },
                //   },
                // ],
            },
        });
        this.setState({ loading: false });
    };
    render() {
        return (
            <>
                {this.state.loading === true ? (
                    <div id="overlay" style={{ display: "block" }}>
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : this.state.series.length !== 0 ? (
                    <Chart
                        options={
                            this.state.options1 !== undefined
                                ? this.state.options1
                                : this.state.options
                        }
                        series={this.state.series}
                        type="bar"
                        height="180px"
                    />
                ) : (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "210px",
                        }}
                    >
                        <h4 style={{ color: "#BBB" }}>
                            No data to display yet
                        </h4>
                    </div>
                )}
            </>
        );
    }
}
export default App;
