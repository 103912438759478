import React, { Component } from "react";

import {
    MDBBtn,
    MDBModalBody,
    MDBModalHeader,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBRow,
    MDBCol,
    toast,
    ToastContainer,
} from "mdbreact";

import axios from "../../../axios";

import DayPickerInput from "react-day-picker/DayPickerInput";

import "react-day-picker/lib/style.css";

import dateFnsFormat from "date-fns/format";

class AddProfile extends Component {
    constructor(props) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleDayChangeEnd = this.handleDayChangeEnd.bind(this);
        this.state = {
            profile: "",
            serviceStart: new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }).format(new Date()),
        };
    }

    componentDidMount() {
        this.makeid();
    }

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    makeid() {
        var result = "";
        var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = 8;
        for (var i = 0; i < 8; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        this.setState({ password: result });
    }

    formatDate(date, format, locale) {
        return dateFnsFormat(date, format, { locale });
    }

    submitHandler = (event) => {
        event.preventDefault();

        event.target.className += " was-validated";
        if (this.state.profile !== "") {
            let body = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                DuplicateKeyID: this.props.keyID,
                UserPartofKey: this.state.password,
                KeyTag: this.state.profile,
                "EndDateTimeUTC.ISO8601":
                    this.state.serviceEnd !== undefined
                        ? new Date(this.state.serviceEnd)
                              .toISOString()
                              .replace(/:00.\d+Z$/g, "Z")
                        : "",
                primary_key: false,
            };
            axios
                .post("/subscriberconsole/addKey", body, {
                    headers: {},
                })
                .then((response) => {
                    this.props.toggleAddProfile();
                    toast.success("Profile added", {
                        closeButton: false,
                    });
                })
                .catch((error) => {
                    this.setState({ error: true });
                    toast.error("Profile Not Added", {
                        closeButton: false,
                    });
                });
        }
    };

    handleDayChange(serviceStart, modifiers, dayPickerInput) {
        const input = dayPickerInput.getInput();
        this.setState({
            serviceStart,
            isEmpty: !input.value.trim(),
            isDisabled: modifiers.disabled === true,
        });
        this.setEndDate(serviceStart);
    }

    handleDayChangeEnd(serviceEnd, modifiers, dayPickerInput) {
        const input = dayPickerInput.getInput();
        this.setState({
            serviceEnd,
            isEmpty: !input.value.trim(),
            isDisabled: modifiers.disabled === true,
        });
    }

    render() {
        const FORMAT = "MM/dd/yyyy";
        return (
            <>
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={10000}
                />
                <MDBModalHeader toggle={this.props.toggleAddProfile}>
                    <h4>Add New Profile</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <form
                        className="needs-validation"
                        onSubmit={this.submitHandler}
                        noValidate
                    >
                        <MDBRow>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Profile
                                </label>
                                <input
                                    required
                                    id="profile"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    name="profile"
                                    value={this.state.profile}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid profile name.
                                </div>
                            </MDBCol>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Activate
                                </label>
                                <DayPickerInput
                                    placeholder="MM/DD/YYYY"
                                    value={this.state.serviceStart}
                                    onDayChange={this.handleDayChange}
                                    formatDate={this.formatDate}
                                    format={FORMAT}
                                    parseDate={this.parseDate}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6">
                                <div className="networkDropdown">
                                    <p>Network</p>

                                    <MDBDropdown>
                                        <MDBDropdownToggle nav caret>
                                            <span className="mr-2">
                                                {this.props.ssidName}
                                            </span>
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                            {this.props.SessionList.map(
                                                (value, index) => {
                                                    return (
                                                        <MDBDropdownItem
                                                            onClick={(e) =>
                                                                this.props.updateNameNetwork(
                                                                    this.props
                                                                        .SessionList[
                                                                        index
                                                                    ][0][
                                                                        "ssid"
                                                                    ],
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            {
                                                                this.props
                                                                    .SessionList[
                                                                    index
                                                                ][0]["ssid"]
                                                            }
                                                        </MDBDropdownItem>
                                                    );
                                                }
                                            )}
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </div>
                            </MDBCol>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Deactivate
                                </label>
                                <DayPickerInput
                                    placeholder="MM/DD/YYYY"
                                    value={this.state.serviceEnd}
                                    onDayChange={this.handleDayChangeEnd}
                                    formatDate={this.formatDate}
                                    format={FORMAT}
                                    parseDate={this.parseDate}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text passwordLabel"
                                >
                                    Password
                                </label>
                                <input
                                    id="editpassword"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    name="password"
                                    value={this.state.password}
                                />
                                <span className="password_subscriber">
                                    .{this.props.password.split(".")[1]}
                                </span>
                                <p>
                                    The .token portion of the password is your
                                    globally secure ID and cannot be changed.
                                </p>
                                <div className="invalid-feedback">
                                    Please provide a valid profile name.
                                </div>
                            </MDBCol>
                            <MDBCol col="6"></MDBCol>
                        </MDBRow>
                        <div className="text-right submitButton">
                            <MDBBtn
                                color="black"
                                onClick={this.props.toggleAddProfile}
                            >
                                Cancel
                            </MDBBtn>
                            <MDBBtn color="danger" type="submit">
                                OK
                            </MDBBtn>
                        </div>
                    </form>
                </MDBModalBody>
            </>
        );
    }
}

export default AddProfile;
