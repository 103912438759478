import React, { Component } from "react";

import axios from "../../../axios";

import {
    MDBIcon,
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    toast,
} from "mdbreact";

import MessagesData from "../../../components/SubscriberMgmt/Messages/Datatables/MessagesData";

import MessagesModal from "../../../components/SubscriberMgmt/Messages/MessagesModal";

import parse from "html-react-parser";

import { withTranslation } from "react-i18next";

class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalMessage: false,
            modalMessageData: false,
        };
    }

    toggleMessage = (key) => {
        this.setState({
            modalMessage: !this.state.modalMessage,
            messageID: key,
        });
    };

    toggleModalMessageData = () => {
        this.setState({
            modalMessageData: !this.state.modalMessageData,
        });
    };

    toggle = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
    };
    submitConfirmMessage = (index) => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            MessageID: index,
        };
        axios
            .post("/subscriberconsole/acknowledgeMessageFromVenue", body, {
                headers: {},
            })
            .then((response) => {
                toast.success("Message Read!", {
                    closeButton: false,
                });
                this.props.listKeys();
                this.setState({
                    modalMessage: !this.state.modalMessage,
                });
            })
            .catch((error) => {
                this.setState({ error: true });
            });
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <MDBModal
                    isOpen={this.state.modalMessageData}
                    toggle={this.toggleModalMessageData}
                    centered
                    size="fluid"
                    className="form messageDataModal"
                >
                    <MessagesModal
                        messages={this.props.messages}
                        toggleMessage={this.toggleMessage}
                        toggleModalMessageData={this.toggleModalMessageData}
                    />
                </MDBModal>
                {this.state.messageID !== undefined ? (
                    <MDBModal
                        isOpen={this.state.modalMessage}
                        toggle={this.toggle}
                        centered
                    >
                        <MDBModalHeader toggle={this.toggle}>
                            {t("messages.title")}
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBRow className="messageSubject">
                                <MDBCol>
                                    <p>{t("messages.subject")}:</p>
                                    {
                                        this.props.messages[
                                            this.state.messageID
                                        ]["Subject"]
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <p>{t("messages.message")}:</p>
                                    {parse(
                                        this.props.messages[
                                            this.state.messageID
                                        ]["Message"]
                                    )}
                                </MDBCol>
                            </MDBRow>
                        </MDBModalBody>
                        <MDBModalFooter>
                            {this.props.messages[this.state.messageID][
                                "newMessage"
                            ] !== false ? (
                                <MDBBtn
                                    color="danger"
                                    onClick={(e) =>
                                        this.submitConfirmMessage(
                                            this.state.messageID
                                        )
                                    }
                                >
                                    {t("messages.acknowledge")}
                                </MDBBtn>
                            ) : (
                                ""
                            )}
                        </MDBModalFooter>
                    </MDBModal>
                ) : (
                    ""
                )}

                <div className="keyInformation">
                    <div
                        className="ctaButton"
                        onClick={this.toggleModalMessageData}
                    >
                        <h2>
                            <MDBIcon far icon="comment-alt" />{" "}
                            {t("messages.title")}
                        </h2>
                    </div>
                    {Object.keys(this.props.messages).length > 0 ? (
                        <MDBCard className="messageData">
                            <MDBCardBody className="messageCardBody1">
                                <MessagesData
                                    messages={this.props.messages}
                                    toggleMessage={this.toggleMessage}
                                />
                            </MDBCardBody>
                        </MDBCard>
                    ) : (
                        <MDBCard
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <MDBCardBody
                                className="messageCardBody1"
                                style={{ flex: "unset" }}
                            >
                                <h4 style={{ color: "#BBB" }}>
                                    {t("messages.nomessages")}
                                </h4>
                            </MDBCardBody>
                        </MDBCard>
                    )}
                </div>
            </>
        );
    }
}

export default withTranslation()(Messages);
