import React, { Component } from "react";

import {
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBRow,
    MDBCol,
    MDBIcon,
    toast,
    ToastContainer,
} from "mdbreact";

import axios from "../../../axios";

import "react-multi-carousel/lib/styles.css";

import Data from "../../../components/SubscriberMgmt/CumulativeBandwidth/Datachart/datachartdevice";

import { withTranslation } from "react-i18next";

class KeyManage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleEdit: "disabled",
            showPassword: false,
            modalEditNickname: false,
            modalEditPassword: false,
            selectedDeviceName: null,
            newDeviceName: null,

            dataLine: {
                labels: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                ],
                datasets: [
                    {
                        label: "My First dataset",
                        fill: true,
                        lineTension: 0.3,
                        backgroundColor: "rgba(225, 204,230, .3)",
                        borderColor: "rgb(205, 130, 158)",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "rgb(205, 130,1 58)",
                        pointBackgroundColor: "rgb(255, 255, 255)",
                        pointBorderWidth: 10,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "rgb(0, 0, 0)",
                        pointHoverBorderColor: "rgba(220, 220, 220,1)",
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: [],
                    },
                    {
                        label: "My Second dataset",
                        fill: true,
                        lineTension: 0.3,
                        backgroundColor: "rgba(184, 185, 210, .3)",
                        borderColor: "rgb(35, 26, 136)",
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "rgb(35, 26, 136)",
                        pointBackgroundColor: "rgb(255, 255, 255)",
                        pointBorderWidth: 10,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: "rgb(0, 0, 0)",
                        pointHoverBorderColor: "rgba(220, 220, 220, 1)",
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: [],
                    },
                ],
            },
        };
    }

    toggleEditNickname = () => {
        this.setState({
            selectedKeyName: this.props.selectedKeyName,
            modalEditNickname: !this.state.modalEditNickname,
        });
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    submitEditNickname = () => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            "Calling-Station-Id": this.props.controllerID,
            UserDefinedHostName: this.state.selectedDeviceName,
        };
        axios
            .post("/subscriberconsole/updateDeviceName", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    modalEditNickname: !this.state.modalEditNickname,
                });
                toast.success("Nickname Updated", {
                    closeButton: false,
                });
                this.props.listKeys();
                this.setState({ newDeviceName: this.state.selectedDeviceName });
            })
            .catch((error) => {
                this.setState({ error: true });
                toast.error("Nickname Not Updated", {
                    closeButton: false,
                });
            });
    };

    renderDeviceIcon(param) {
        switch (param) {
            case "NetworkDevice":
                return "mobile-alt";
            case "Laptop":
                return "laptop";
            case "Unknown":
                return "question";
            default:
                return "mobile-alt";
        }
    }

    render() {
        let callingStationID = this.props.deviceDetails["Calling-Station-Id"];
        const { t } = this.props;
        return (
            <>
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={10000}
                />

                <MDBModal
                    isOpen={this.state.modalEditNickname}
                    toggle={this.toggle}
                    size="lg"
                    className="form"
                    centered
                >
                    <MDBModalHeader toggle={this.toggleEditNickname}>
                        <h4>Edit Nickname</h4>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol col="6">
                                <label
                                    htmlFor="defaultFormLoginEmailEx"
                                    className="black-text"
                                >
                                    Nickname
                                </label>
                                <input
                                    id="nickname"
                                    className="form-control"
                                    onChange={this.changeHandler}
                                    name="selectedDeviceName"
                                    value={
                                        this.state.selectedDeviceName === null
                                            ? this.props.deviceDetails[
                                                  "UserDefinedHostName"
                                              ]
                                            : this.state.selectedDeviceName
                                    }
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid email.
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <div className="text-right">
                            <MDBBtn
                                color="danger"
                                onClick={this.submitEditNickname}
                            >
                                Submit
                            </MDBBtn>
                            <MDBBtn
                                color="danger"
                                onClick={this.toggleEditNickname}
                            >
                                Cancel
                            </MDBBtn>
                        </div>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModalHeader toggle={this.props.toggleDeviceDetails}>
                    <h4>{t("devicedetails.title")}</h4>
                </MDBModalHeader>
                <MDBModalBody
                    style={{ margin: "0px 20px 0px 10px" }}
                    className="deviceDetails"
                >
                    <MDBRow>
                        <MDBCol sm="12" className="pb-3">
                            <MDBRow style={{ marginLeft: 0 }}>
                                <MDBIcon
                                    icon={this.renderDeviceIcon(
                                        this.props.deviceDetails["DeviceType"]
                                    )}
                                />
                                <div
                                    className={
                                        this.props.deviceDetails[
                                            "currentlyOnline"
                                        ] === true
                                            ? "deviceStatusConnected"
                                            : "deviceStatusDisconnected"
                                    }
                                ></div>
                                <div className="formControls">
                                    <p style={{ marginRight: "10px" }}>
                                        {this.state.newDeviceName === null
                                            ? this.props.deviceDetails[
                                                  "UserDefinedHostName"
                                              ]
                                            : this.state.newDeviceName}
                                    </p>
                                </div>
                                <div className="formControls">
                                    <MDBIcon
                                        icon="edit"
                                        onClick={this.toggleEditNickname}
                                    />
                                </div>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol sm="6">
                            <div className="form-group row">
                                <div className="col-sm-3">
                                    <p>
                                        <strong>
                                            {t("devicedetails.profile")}
                                        </strong>
                                    </p>
                                </div>
                                <div className="col-sm-9">
                                    <p>
                                        {
                                            this.props.deviceDetails[
                                                "Profiles"
                                            ][0]
                                        }
                                    </p>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-sm-3">
                                    <p>
                                        <strong>
                                            {t("devicedetails.network")}
                                        </strong>
                                    </p>
                                </div>
                                <div className="col-sm-9">
                                    <p>{this.props.deviceDetails["ssid"][0]}</p>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-3">
                                    <p>
                                        <strong>
                                            {t("devicedetails.mac")}
                                        </strong>
                                    </p>
                                </div>
                                <div className="col-sm-9">
                                    <p>
                                        {
                                            this.props.deviceDetails[
                                                "Calling-Station-Id"
                                            ]
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-3">
                                    <p>
                                        <strong>
                                            {t("devicedetails.ipaddress")}
                                        </strong>
                                    </p>
                                </div>
                                <div className="col-sm-9">
                                    <p>
                                        {this.props.deviceDetails[
                                            "Framed-IP-Address"
                                        ].map((ipaddy, i) => {
                                            if (ipaddy !== "") {
                                                // Return the element. Also pass key
                                                return ipaddy + " ";
                                            }
                                            return true;
                                        })}
                                    </p>
                                </div>
                            </div>
                        </MDBCol>
                        <MDBCol sm="6">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <p>
                                            <strong>
                                                {t("devicedetails.firstused")}
                                            </strong>
                                        </p>
                                    </div>
                                    <div className="col-sm-8">
                                        <p>
                                            {new Intl.DateTimeFormat("en-US", {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit",
                                                hour: "numeric",
                                                minute: "numeric",
                                            }).format(
                                                new Date(
                                                    this.props.deviceDetails[
                                                        "dateAddedUTC.ISO8601"
                                                    ]
                                                )
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <p>
                                            <strong>
                                                {t("devicedetails.lastused")}
                                            </strong>
                                        </p>
                                    </div>
                                    <div className="col-sm-8">
                                        <p>
                                            {new Intl.DateTimeFormat("en-US", {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit",
                                                hour: "numeric",
                                                minute: "numeric",
                                            }).format(
                                                new Date(
                                                    this.props.deviceDetails[
                                                        "mostRecentUseUTC.ISO8601"
                                                    ]
                                                )
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol
                            sm="12"
                            style={{
                                border: "1px solid #CCC",
                                padding: "10px",
                                borderRadius: "5px",
                                minHeight: "265px",
                            }}
                        >
                            <p>
                                <strong>{t("devicedetails.bandwidth")}</strong>
                            </p>
                            <Data callingStationID={callingStationID} />
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                    <div className="text-right">
                        <MDBBtn
                            color="danger"
                            onClick={this.props.toggleDeviceDetails}
                        >
                            OK
                        </MDBBtn>
                    </div>
                </MDBModalFooter>
            </>
        );
    }
}

export default withTranslation()(KeyManage);
